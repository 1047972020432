import { Component, ElementRef, OnInit } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { map, distinctUntilChanged } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { includeSprites } from 'src/decking/sprites';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { DeckingSubstitutionZonesAlternativesService } from 'src/decking/services/decking-zones/alternatives/decking-substitution-zones-alternatives.service';
import { CompareZoneAlternativesCommonComponent } from 'src/decking/components/decking-main-bottom/decking-zones/compare-zone-alternatives/compare-zone-alternatives-common.component';

@Component({
    templateUrl: './compare-substitution-zone-alternatives.component.html',
    styleUrls: ['./compare-substitution-zone-alternatives.component.scss']
})
export class CompareSubstitutionZoneAlternativesComponent extends CompareZoneAlternativesCommonComponent implements OnInit {
    currentZone: SubstitutionZoneModel;

    constructor(
        private deckingSubstitutionService: DeckingSubstitutionService,
        private deckingSubstitutionZonesAlternativesService: DeckingSubstitutionZonesAlternativesService,
        protected override deckingUnitsHelperService: DeckingUnitsHelperService,
        protected override deckingCodeListService: DeckingCodeListService,
        protected elementRef: ElementRef<HTMLElement>
    ) {
        super(deckingUnitsHelperService, deckingCodeListService);
    }

    async ngOnInit(): Promise<void> {
        includeSprites(this.elementRef.nativeElement,
            'sprite-checkbox'
        );
        this.isBySpacing$ = this.deckingSubstitutionService.currentArea$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.currentZone = this.deckingSubstitutionService.currentZone;
        this.currentSettings$ = this.deckingSubstitutionService.currentSettings$;
        this.isImperialUnit$ = this.currentSettings$.pipe(map(s => s.length.id),
            distinctUntilChanged(),
            map((unitValue: Unit) => {
                return !this.deckingUnitsHelperService.isInternationalSystemUnit(unitValue);
            }));
        this.initUnits();
        await this.loadAlternatives(this.currentZone.alternatives);
    }

    public includeInReport() {
        if (this.selectedAlternatives.size <= this.maxAllowedSelection) {
            this.isSubmitting = true;
            this.deckingSubstitutionService.updateCurrentZone(this.currentZone);
            this.deckingSubstitutionZonesAlternativesService.setAlternatives(this.deckingSubstitutionService.getCurrentSubstitution());
            this.close();
        }
    }
    
    protected override initLengthUnit(): void {
        this.lengthUnit$ = this.deckingSubstitutionService.currentSettings$.pipe(
            map(s => s.length.id),
            distinctUntilChanged(),
        );
    }

    protected override async loadAlternativesFromBackend(): Promise<void> {
        const currentDesign = cloneDeep(this.deckingSubstitutionService.getCurrentSubstitution());
        const zoneAlternatives = await this.deckingSubstitutionZonesAlternativesService.getAlternatives(currentDesign);
        this.alternatives = zoneAlternatives;
        this.currentZone.alternatives = zoneAlternatives;
    }
}
