import { BaseAreaModel } from './../../entities/decking-design/area-model';
import { NumberComparator } from 'src/decking/helpers/numbers/number-comparator';

export class BaseAreas {
    protected areBaseAreaInputsEquals(currentArea: BaseAreaModel, newArea: BaseAreaModel) {
        return currentArea?.name.value === newArea?.name.value &&
            currentArea?.deckFill.id === newArea.deckFill?.id &&
            currentArea?.deckType.id === newArea.deckType?.id &&
            NumberComparator.equal(currentArea?.compressiveStrength ? currentArea?.compressiveStrength.value : 0, newArea.compressiveStrength ? newArea.compressiveStrength?.value : 0) &&
            NumberComparator.equal(currentArea?.fillThickness.value, newArea.fillThickness?.value) &&
            currentArea?.deckPanel.id === newArea.deckPanel?.id &&
            currentArea?.panelType.id === newArea.panelType?.id &&
            NumberComparator.equal(currentArea?.panelWidth.value, newArea.panelWidth?.value) &&
            NumberComparator.equal(currentArea?.beam?.spacing.value, newArea.beam?.spacing.value) &&
            NumberComparator.equal(currentArea?.beam?.thickness.value, newArea.beam?.thickness?.value) &&
            currentArea?.supportConstruction.id === newArea.supportConstruction?.id &&
            currentArea?.zoneSpacing.value === newArea.zoneSpacing?.value &&
            currentArea?.panelType.id === newArea.panelType?.id &&
            currentArea?.definitionOfSidelapConnectors.id === newArea.definitionOfSidelapConnectors.id &&
            currentArea?.definitionOfSidelapConnectors.index === newArea.definitionOfSidelapConnectors.index &&
            currentArea?.definitionOfSidelapConnectors.value === newArea.definitionOfSidelapConnectors.value &&
            currentArea?.sidelapsSpacingSettings.sidelapSpacingFrom.value === newArea.sidelapsSpacingSettings.sidelapSpacingFrom.value &&
            currentArea?.sidelapsSpacingSettings.sidelapSpacingIncrement.value === newArea.sidelapsSpacingSettings.sidelapSpacingIncrement.value &&
            currentArea?.sidelapsSpacingSettings.sidelapSpacingTo.value === newArea.sidelapsSpacingSettings.sidelapSpacingTo.value &&
            currentArea?.sidelapsNumberSettings.numberOfSidelapsFrom.value === newArea.sidelapsNumberSettings.numberOfSidelapsFrom.value &&
            currentArea?.sidelapsNumberSettings.numberOfSidelapsIncrement.value === newArea.sidelapsNumberSettings.numberOfSidelapsIncrement.value &&
            currentArea?.sidelapsNumberSettings.numberOfSidelapsTo.value === newArea.sidelapsNumberSettings.numberOfSidelapsTo.value;
    }
}