import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { SortablejsModule } from 'ngx-sortablejs';
import { FilterVerifiedZones } from 'src/decking/pipes/filter-verified-zones';
import { SidelapConnectorTitle } from 'src/decking/pipes/sidelap-connector-title';
import { L10nDirective } from 'src/decking/directives/l10n.directive';
import { L10nPipe } from 'src/decking/pipes/l10n.pipe';
import { SelectTextDirective } from 'src/decking/directives/select-text-on-click.directive';
import { UiDeckingInitComponent } from './components/ui-decking-init.component/ui-decking-init.component';
import { DeckingSettingsDiaphragmComponent } from './components/decking-settings/decking-settings-diaphragm/decking-settings-diaphragm.component';
import { DeckingSettingsMenuComponent } from './components/decking-settings/decking-settings-menu/decking-settings-menu.component';
import { NumericUpDownComponent } from './components/common/numeric-up-down/numeric-up-down.component';
import { DeckingButtonGroupComponent } from './components/common/decking-button-group/decking-button-group.component';
import { DeckingDiaphragmDesignSettingsComponent } from './components/decking-settings/decking-diaphragm-design-settings/decking-diaphragm-design-settings.component';
import { ModeSelectorComponent } from './components/common/mode-selector/mode-selector.component';
import { DeckingZoneSideComponent } from './components/common/decking-zone-side/decking-zone-side.component';
import { SquareIconButtonComponent } from './components/common/square-icon-button/square-icon-button.component';
import { CollapsableStripedTitleComponent } from './components/common/collapsable-striped-title/collapsable-striped-title.component';
import { CollapsableUpComponent } from './components/common/collapsable-up/collapsable-up.component';
import { ConfirmChangeComponent } from './components/common/confirm-change/confirm-change.component';
import { DeckingRadioButtonComponent } from './components/common/decking-radio-button/decking-radio-button.component';
import { DesignerLayoutComponent } from './components/layouts/designer-layout/designer-layout.component';
import { ProgressBarComponent } from './components/common/progress-bar/progress-bar.component';
import { DeckingReportButtonComponent } from './components/common/decking-report-button/decking-report-button.component';
import { TopBarComponent } from './components/decking-main-top/top-bar/top-bar.component';
import { DeckingUtilizationResultComponent } from './components/decking-main-right/decking-utilization-result/decking-utilization-result.component';
import { UnitValueDisplayComponent } from './components/common/unit-value-display/unit-value-display.component';
import { TopBarMenuComponent } from './components/decking-main-top/top-bar-menu/top-bar-menu.component';
import { UndoRedoComponent } from './components/common/undo-redo/undo-redo.component';
import { MessageBasicComponent } from './components/common/message-basic/message-basic.component';
import { MessageWithButtonComponent } from './components/common/message-with-button/message-with-button.component';
import { DeckingDropdownComponent } from './components/common/decking-dropdown/decking-dropdown.component';
import { ZoneTemplatesComponent } from './components/decking-main-bottom/decking-zones/zones-templates/zone-templates.component';
import { TextBoxComponent } from './components/common/text-box/text-box.component';
import { NumericTextBoxComponent } from './components/common/numeric-text-box/numeric-text-box.component';
import { DeckingMainBottomComponent } from './components/decking-main-bottom/decking-main-bottom.component';
import { DeckingZonesActionButtonsComponent } from './components/decking-main-bottom/decking-zones/action-buttons/decking-zones-action-buttons.component';
import { CompareZoneAlternativesComponent } from './components/decking-main-bottom/decking-zones/compare-zone-alternatives/compare-zone-alternatives.component';
import { DeckingZonesHeaderComponent } from './components/decking-main-bottom/decking-zones/header/decking-zones-header.component';
import { DeckingZonesComponent } from './components/decking-main-bottom/decking-zones/decking-zones.component';
import { DeckingZonesRowComponent } from './components/decking-main-bottom/decking-zones/row/decking-zones-row.component';
import { DeckingZonesRowActionButtonsComponent } from './components/decking-main-bottom/decking-zones/row/action-buttons/decking-zones-row-action-buttons.component';
import { FastenerSelectionComponent } from './components/decking-main-bottom/fastener-selection/fastener-selection.component';
import { DeckingBootstrapDropdownComponent } from './components/common/decking-bootstrap-dropdown/decking-bootstrap-dropdown';
import { DeckingMainRightComponent } from './components/decking-main-right/decking-main-right.component';
import { DeckingMainRightMessagesComponent } from './components/decking-main-right/decking-main-right-messages/decking-main-right-messages.component';
import { DeckingMainRightOptionsComponent } from './components/decking-main-right/decking-main-right-options/decking-main-right-options.component';
import { DeckingReportGenerationOptionsSummaryComponent } from './components/decking-popup/decking-report-generator/decking-report-summary/decking-report-generation-options-summary.component';
import { DeckingReportGenerationOptionsDetailsComponent } from './components/decking-popup/decking-report-generator/decking-report-details/decking-report-generation-options-details.component';
import { DeckingReportGenerationOptionsAreaSelectionComponent } from './components/decking-popup/decking-report-generator/decking-report-area-selection/decking-report-generation-options-area-selection.component';
import { DeckingReportGenerationOptionsLayoutComponent } from './components/decking-popup/decking-report-generator/decking-report-layout/decking-report-generation-options-layout.component';
import { FastenerEstimationComponent } from './components/decking-main-right/decking-main-right-messages/fastener-estimation/fastener-estimation.component';
import { FastenerEstimationRowComponent } from './components/decking-main-right/decking-main-right-messages/fastener-estimation-row/fastener-estimation-row.component';
import { DeckingReportGeneratorComponent } from './components/decking-popup/decking-report-generator/decking-report-generator.component';
import { SpecificationTextComponent } from './components/decking-popup/specification-text/specification-text.component';
import { SubstitutionSpecificationTextComponent } from './components/decking-substitution/substitution-popup/substitution-specification-text/substitution-specification-text.component';
import { DeflectionCalculationComponent } from './components/decking-popup/deflection-calculation/deflection-calculation.component';
import { PreviewDialogComponent } from './components/decking-popup/decking-report-generator/decking-report-preview-dialog/preview-dialog.component';
import { DeckingResultsPanelComponent } from './components/decking-main-right/decking-results-panel/decking-results-panel.component';
import { CollapsableContentDirective } from './directives/collapsable-content.directive';
import { EmbeddedTitleDirective } from './directives/embedded-title.directive';
import { ExportDesignComponent } from './components/decking-popup/export-design/export-design.component';
import { DeckingReportTemplateDisplayComponent } from './components/decking-popup/decking-report-generator/decking-report-layout/report-template-display/decking-report-template-display.component';
import { DeckingReportGenerationOptionsActionButtonsComponent } from './components/decking-popup/decking-report-generator/decking-report-action-buttons/decking-report-generation-options-action-buttons.component';
import { DiaphragmStrengthComponent } from './components/decking-popup/diagram-strength/diaphragm-strength.component';
import { PanelTypeInfoComponent } from './components/decking-popup/panel-type-info/panel-type-info.component';
import { SaveAsDeckingTemplateComponent } from './components/decking-popup/save-as-decking-template/save-as-decking-template.component';
import { ZoneOrientationComponent } from './components/decking-popup/zone-orientation/zone-orientation.component';
import { DeckingMainTopComponent } from './components/decking-main-top/main-top.component';
import { SubstitutionMainTopComponent } from './components/decking-substitution/substitution-main-top/substitution-main-top.component';
import { SubstitutionAreasWrapperComponent } from './components/decking-substitution/substitution-main-left/substitution-areas-wrapper/substitution-areas-wrapper.component';
import { SubstitutionAreaComponent } from './components/decking-substitution/substitution-main-left/substitution-area/substitution-area.component';
import { SubstitutionAreaPropertiesComponent } from './components/decking-substitution/substitution-main-left/substitution-area-properties/substitution-area-properties.component';
import { SubstitutionMainRightComponent } from './components/decking-substitution/substitution-main-right/substitution-main-right.component';
import { SubstitutionMainRightMessagesComponent } from './components/decking-substitution/substitution-main-right/substitution-main-right-messages/substitution-main-right-messages.component';
import { SubstitutionMainRightOptionsComponent } from './components/decking-substitution/substitution-main-right/substitution-main-right-options/substitution-main-right-options.component';
import { ExportSubstitutionComponent } from './components/decking-substitution/substitution-popup/export-substitution/export-substitution.component';
import { DeckingSubstitutionZonesComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/decking-substitution-zones.component';
import { DeckingSubstitutionZonesActionButtonsComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/action-buttons/decking-substitution-zones-action-buttons.component';
import { DeckingSubstitutionZonesHeaderComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/header/decking-substitution-zones-header.component';
import { DeckingSubstitutionZonesRowActionButtonsComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/row/action-buttons/decking-substitution-zones-row-action-buttons.component';
import { DeckingSubstitutionZonesRowComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/row/decking-substitution-zones-row.component';
import { SubstitutionSideComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/side/side.component';
import { SubstitutionFastenerEstimationComponent } from './components/decking-substitution/substitution-main-right/substitution-main-right-messages/substitution-fastener-estimation/substitution-fastener-estimation.component';
import { SubstitutionFastenerEstimationRowComponent } from './components/decking-substitution/substitution-main-right/substitution-main-right-messages/substitution-fastener-estimation-row/substitution-fastener-estimation-row.component';
import { FilterSubstitutedZones } from './pipes/filter-substituted-zones';
import { ProductSelectorComponent } from './components/decking-substitution/substitution-main-centre/product-selector/product-selector.component';
import { ProductSelectorListComponent } from './components/common/product-selector/product-selector-list/product-selector-list.component';
import { SubstitutionSidelapConnectorTitle } from './pipes/substitution-sidelap-connector-title';
import { FilterHiltiProducts } from './pipes/filter-hilti-products';
import { DeckingMainComponent } from './components/decking-main/decking-main.component';
import { DeckingMainLeftComponent } from './components/decking-main-left/decking-main-left.component';
import { LeftBarRowComponent } from './components/decking-main-left/decking-main-left-row/decking-main-left-row.component';
import { DeckingMainCenterComponent } from './components/decking-main-center/decking-main-center.component';
import { DeckingDesignAreasWrapperComponent } from './components/decking-main-left/decking-design-areas-wrapper/decking-design-areas-wrapper.component';
import { DeckingDesignAreaComponent } from './components/decking-main-left/decking-design-area/decking-design-area.component';
import { DeckingDesignAreaPropertiesComponent } from './components/decking-main-left/decking-design-area-properties/decking-design-area-properties.component';
import { DesignProductSelectorComponent } from './components/decking-main-center/design-product-selector/design-product-selector.component';
import { RenameDesignComponent } from './components/decking-popup/rename-design/rename-design.component';
import { CompareSubstitutionZoneAlternativesComponent } from './components/decking-substitution/substitution-main-bottom/decking-substitution-zones/compare-substitution-zone-alternatives/compare-substitution-zone-alternatives.component';
import { DesignSectionWrapperComponent } from './components/common/design-section/design-section-wrapper.component';


@NgModule({
  declarations: [
    L10nDirective,
    L10nPipe,
    FilterVerifiedZones,
    FilterSubstitutedZones,
    FilterHiltiProducts,
    SidelapConnectorTitle,
    SubstitutionSidelapConnectorTitle,
    CollapsableContentDirective,
    SelectTextDirective,
    EmbeddedTitleDirective,
    DeckingSettingsDiaphragmComponent,
    DeckingButtonGroupComponent,
    DeckingSettingsMenuComponent,
    CollapsableStripedTitleComponent,
    CollapsableUpComponent,
    ConfirmChangeComponent,
    DeckingDropdownComponent,
    DeckingBootstrapDropdownComponent,
    DeckingRadioButtonComponent,
    DesignerLayoutComponent,
    ProgressBarComponent,
    DeckingReportButtonComponent,
    MessageBasicComponent,
    MessageWithButtonComponent,
    TopBarComponent,
    TopBarMenuComponent,
    UndoRedoComponent,
    UnitValueDisplayComponent,
    DeckingUtilizationResultComponent,
    NumericUpDownComponent,
    DeckingDiaphragmDesignSettingsComponent,
    ModeSelectorComponent,
    ZoneTemplatesComponent,
    DeckingZoneSideComponent,
    SquareIconButtonComponent,
    DeckingMainBottomComponent,
    DeckingSubstitutionZonesComponent,
    DeckingSubstitutionZonesActionButtonsComponent,
    DeckingSubstitutionZonesHeaderComponent,
    DeckingSubstitutionZonesRowActionButtonsComponent,
    DeckingSubstitutionZonesRowComponent,
    DeckingZonesActionButtonsComponent,
    DeckingZonesHeaderComponent,
    FastenerSelectionComponent,
    DeckingZonesComponent,
    DeckingZonesRowComponent,
    DeckingZonesRowActionButtonsComponent,
    CompareZoneAlternativesComponent,
    CompareSubstitutionZoneAlternativesComponent,
    TextBoxComponent,
    NumericTextBoxComponent,
    ExportDesignComponent,
    ExportSubstitutionComponent,
    DeckingMainRightMessagesComponent,
    SubstitutionMainRightMessagesComponent,
    DeckingMainRightOptionsComponent,
    SubstitutionMainRightOptionsComponent,
    DeckingResultsPanelComponent,
    FastenerEstimationComponent,
    FastenerEstimationRowComponent,
    DiaphragmStrengthComponent,
    PanelTypeInfoComponent,
    SaveAsDeckingTemplateComponent,
    ZoneOrientationComponent,
    DeckingReportTemplateDisplayComponent,
    DeckingReportGenerationOptionsActionButtonsComponent,
    DeckingReportGenerationOptionsSummaryComponent,
    DeckingReportGenerationOptionsDetailsComponent,
    DeckingReportGenerationOptionsAreaSelectionComponent,
    DeckingReportGenerationOptionsLayoutComponent,
    DeckingReportGeneratorComponent,
    SpecificationTextComponent,
    SubstitutionSpecificationTextComponent,
    DeflectionCalculationComponent,
    PreviewDialogComponent,
    DeckingMainRightComponent,
    SubstitutionMainRightComponent,
    DeckingMainTopComponent,
    SubstitutionMainTopComponent,
    SubstitutionAreasWrapperComponent,
    SubstitutionAreaComponent,
    SubstitutionAreaPropertiesComponent,
    SubstitutionSideComponent,
    SubstitutionFastenerEstimationComponent,
    SubstitutionFastenerEstimationRowComponent,
    ProductSelectorComponent,
    ProductSelectorListComponent,
    DeckingMainComponent,
    DeckingMainLeftComponent,
    LeftBarRowComponent,
    DeckingMainCenterComponent,
    DeckingDesignAreasWrapperComponent,
    DeckingDesignAreaComponent,
    DeckingDesignAreaPropertiesComponent,
    DesignProductSelectorComponent,
    RenameDesignComponent,
    DesignSectionWrapperComponent
  ],
  imports: [
    BrowserModule,
    NgbTooltipModule,
    NgbDropdownModule,
    FormsModule,
    VirtualScrollerModule,
    SortablejsModule.forRoot({})
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-decking/' }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  constructor(
    private injector: Injector,
    ngbTooltipConfig: NgbTooltipConfig
  ) {
    this.configureTooltip(ngbTooltipConfig);
    this.registerCustomElement('ui-decking-init', UiDeckingInitComponent);
    this.registerCustomElement('settings-diaphragm', DeckingSettingsDiaphragmComponent);
    this.registerCustomElement('settings-menu', DeckingSettingsMenuComponent);
    this.registerCustomElement('diaphragm-design-settings', DeckingDiaphragmDesignSettingsComponent);
    this.registerCustomElement('mode-selector', ModeSelectorComponent);
    this.registerCustomElement('substitution-zones', DeckingSubstitutionZonesComponent);
    this.registerCustomElement('substitution-zones-action-buttons', DeckingSubstitutionZonesActionButtonsComponent);
    this.registerCustomElement('substitution-zones-row-action-buttons', DeckingSubstitutionZonesRowActionButtonsComponent);
    this.registerCustomElement('substitution-main-top', SubstitutionMainTopComponent);
    this.registerCustomElement('substitution-areas-wrapper', SubstitutionAreasWrapperComponent);
    this.registerCustomElement('zones-action-buttons', DeckingZonesActionButtonsComponent);
    this.registerCustomElement('compare-zone-alternatives-modal', CompareZoneAlternativesComponent);
    this.registerCustomElement('compare-substitution-zone-alternatives-modal', CompareSubstitutionZoneAlternativesComponent);
    this.registerCustomElement('zones', DeckingZonesComponent);
    this.registerCustomElement('zones-row-action-buttons', DeckingZonesRowActionButtonsComponent);
    this.registerCustomElement('fastener-selection', FastenerSelectionComponent);
    this.registerCustomElement('zone-orientation', ZoneOrientationComponent);
    this.registerCustomElement('panel-type-info', PanelTypeInfoComponent);
    this.registerCustomElement('diaphragm-strength', DiaphragmStrengthComponent);
    this.registerCustomElement('substitution-main-bottom', DeckingMainBottomComponent);
    this.registerCustomElement('text-box', TextBoxComponent);
    this.registerCustomElement('numeric-text-box', NumericTextBoxComponent);
    this.registerCustomElement('substitution-main-right', SubstitutionMainRightComponent);
    this.registerCustomElement('main-layout', DeckingMainComponent);
    this.registerCustomElement('rename-design', RenameDesignComponent);
    this.registerCustomElement('design-section-wrapper', DesignSectionWrapperComponent);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
  public ngDoBootstrap() {
    // Nothing to do.
  }

  private configureTooltip(config: NgbTooltipConfig) {
    config.container = 'body';
    config.triggers = 'hover';
    config.openDelay = 500;
  }

  private registerCustomElement(componentName: string, component: Type<any>) {
    const customElement = createCustomElement(component, { injector: this.injector });
    customElements.define(`decking-${componentName}`, customElement);
  }
}
