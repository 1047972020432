import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { LocalizationService } from './../../../services/external/localization.service';
import { AreaModel } from './../../../entities/decking-design/area-model';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingScopeChecksService } from './../../../services/decking-scope-checks/decking-scope-checks.service';
import { DesignSettings } from './../../../entities/settings/design-settings';

@Component({
    selector: 'decking-results-panel',
    templateUrl: './decking-results-panel.component.html',
    styleUrls: ['./decking-results-panel.component.scss']
})
export class DeckingResultsPanelComponent implements OnInit, OnChanges {
    @Input()
    public currentArea: AreaModel;
    public areaSummaryLabel: string;
    public isRequiredShearStiffnessSetting$: Observable<boolean>;
    public isRequiredUpliftSubmittalSetting$: Observable<boolean>;
    public zonesWithCriticalScopeChecks$: Observable<string[]>;
    public currentSettings$: Observable<DesignSettings>;
    private readonly MAX_AREA_NAME_LENGTH: number = 20;
    public shearStiffnessUnit$: Observable<Unit>;

    constructor(
        public localization: LocalizationService,
        private deckingDesignService: DeckingDesignService,
        private deckingScopeChecksService: DeckingScopeChecksService
    ) { }

    ngOnInit(): void {
        this.currentSettings$ = this.deckingDesignService.currentSettings$;
        this.shearStiffnessUnit$ = this.currentSettings$.pipe(map(s => s.shearStiffness.id), distinctUntilChanged());
        this.isRequiredShearStiffnessSetting$ = this.deckingDesignService.isRequiredShearStiffnessSetting$;
        this.isRequiredUpliftSubmittalSetting$ = this.deckingDesignService.isRequiredUpliftSubmittalSetting$;
        this.zonesWithCriticalScopeChecks$ = this.deckingScopeChecksService.getAreaCriticalScopeChecksZones(this.deckingDesignService.currentArea$);
    }

    ngOnChanges(): void {
        this.areaSummaryLabel = format(this.localization.getString('Agito.Hilti.Profis3.Decking.Results.AreaSummary.Title'), this.currentArea?.name.value.substring(0, this.MAX_AREA_NAME_LENGTH - 1));
        this.areaSummaryLabel += this.areaSummaryLabel.length > this.MAX_AREA_NAME_LENGTH ? '...' : '';
    }
}
