<div class="modal-header" id="decking-zones-compare-alternatives-dialog">
    <h3
        class="modal-title"
        l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.CompareAlternatives"
    ></h3>

    <button
        type="button"
        class="close"
        (click)="close()"
        [disabled]="isSubmitting"
    >
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <ng-container *ngIf="currentZone; else noZone">
        <div class="title">
            <strong>{{currentZone.name.value}}</strong>
        </div>
        <table class="table-archive" aria-label="Zone Alternatives table" >
            <thead>
                <tr class="table-archive-row-header">
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener"></th>
                    <th class="fastener-spacing" [l10n]="'Agito.Hilti.Profis3.Decking.Zones.Subheader.' | sidelapConnectorTitle"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearUtilization"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.UpliftUtilization"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearStiffness"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Cost"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-archive-row">
                    <td>{{(currentZone?.zoneSubstituted.deckGauge?.value ?? '-') + DECK_GAUGE_UNIT}}</td>
                    <td>{{currentZone?.zoneSubstituted.pattern?.value ?? '-'}}</td>
                    <td>{{getFastenerName(currentZone?.zoneSubstituted.frameFastener?.id) ?? '-'}}</td>
                    <td>{{getSidelapName(currentZone?.zoneSubstituted.sidelapConnector?.id) ?? '-'}}</td>
                    <td><decking-unit-value-display [value]="currentZone?.zoneSubstituted.side?.value" [precision]="0" [unit]="sidelapSpacingUnit$ | async" [appendUnit]="isBySpacing$ | async"></decking-unit-value-display></td>
                    <td>{{(currentZone?.zoneSubstituted.result?.diaphragmShearPercentage?.value) | percent:'1.0-2'}}</td>
                    <td>{{currentZone?.zoneSubstituted.result?.upliftPercentage?.value | percent:'1.0-2'}}</td>
                    <td><decking-unit-value-display [value]="currentZone.zoneSubstituted.result?.gLoad?.value" [unit]="shearStiffnessUnit$ | async" [appendUnit]="true"></decking-unit-value-display></td>
                    <td>{{currentZone?.zoneSubstituted.result?.cost?.value ?? '-'}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-template #noZone>
        No zone selected
    </ng-template>


    <div class="title">
        <strong l10n="Agito.Hilti.Profis3.Decking.Zones.AlternativeSolutions"></strong>
    </div>
    <table class="table-archive" aria-label="Zone Alternatives table" >
        <thead>
            <tr class="table-archive-row-header">
                <th>
                    <label class="checkbox">
                        <span class="checkbox-wrapper">
                            <span class="checkbox-label"></span>
                        </span>
                    </label>
                </th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener"></th>
                <th class="fastener-spacing" [l10n]="'Agito.Hilti.Profis3.Decking.Zones.Subheader.' | sidelapConnectorTitle"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearUtilization"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.UpliftUtilization"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearStiffness"></th>
                <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Cost"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="table-archive-row" [ngClass]="{selected: alternative.selected}" *ngFor="let alternative of alternatives">
                <td class="checkbox-padding">
                    <label class="checkbox">
                        <span class="checkbox-wrapper">
                            <input
                                [checked]="alternative.selected"
                                [disabled]="selectedAlternatives.size >= maxAllowedSelection && !alternative.selected"
                                (change)="selectAlternative(alternative, $any($event).target.checked)"
                                type="checkbox"
                                class="checkbox-input">
                            <span  [ngClass]="alternative.selected ? 'checkbox-button decking-sprite decking-sprite-checkbox' : 'checkbox-button'"></span>
                            <span class="checkbox-label"></span>
                        </span>
                    </label>
                </td>
                <td>{{(alternative.deckGauge?.value ?? '-') + DECK_GAUGE_UNIT}}</td>
                <td>{{alternative.pattern?.value ?? '-'}}</td>
                <td>{{getFastenerName(alternative.frameFastener?.id) ?? '-'}}</td>
                <td>{{getSidelapName(alternative.sidelapConnector?.id) ?? '-'}}</td>
                <td><decking-unit-value-display [value]="alternative.side?.value" [precision]="0" [unit]="sidelapSpacingUnit$ | async" [appendUnit]="isBySpacing$ | async"></decking-unit-value-display></td>
                <td>{{alternative.result?.q?.value | percent:'1.0-2'}}</td>
                <td>{{alternative.result?.w?.value | percent:'1.0-2'}}</td>
                <td><decking-unit-value-display [value]="alternative.result?.g?.value" [unit]="shearStiffnessUnit$ | async" [appendUnit]="true"></decking-unit-value-display></td>
                <td>{{alternative.result?.cost?.value | number : '1.2-2' ?? '-'}}</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal-footer">
    <div id="counter">
        {{ selectedAlternatives.size }}
        <span l10n="Agito.Hilti.Profis3.Decking.Zones.AlternativeSelected"></span> &nbsp;
        <span *ngIf="selectedAlternatives.size >= maxAllowedSelection" l10n="Agito.Hilti.Profis3.Decking.Zones.MaximumReached"></span>
    </div>
    <button
        type="button"
        id="add-edit-design-cancel-button"
        class="modal-button button button-default"
        l10n="Agito.Hilti.Profis3.AddEditDesign.Cancel"
        (click)="close()"
        [disabled]="isSubmitting"
    ></button>

    <button
        type="submit"
        id="add-edit-design-save-button"
        class="modal-button button button-CTA"
        (click)="includeInReport()"
        [disabled]="isSubmitting"
    >
        <pe-loading-button
            [loading]="isSubmitting"
            l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.IncludeInReport">
        </pe-loading-button>
    </button>
</div>
