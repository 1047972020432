import { Component, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeckingUrlPath } from 'src/decking/entities/module-constants';
import { DeckingDocumentService } from 'src/decking/services/decking-document/decking-document.service';
import { ImportService } from 'src/decking/services/external/import.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { DeckingSubstitutionDefaultFactoryService } from 'src/decking/services/decking-design/factory/decking-substitution-default-factory.service';
import { BaseDeckingMainRightOptionsComponent } from '../../../decking-main-right/decking-main-right-options/base-decking-main-right-options-component';
import { ExportSubstitutionComponent } from '../../substitution-popup/export-substitution/export-substitution.component';

@Component({
  selector: 'substitution-main-right-options',
  templateUrl: './substitution-main-right-options.component.html',
  styleUrls: ['./substitution-main-right-options.component.scss'],
})
export class SubstitutionMainRightOptionsComponent extends BaseDeckingMainRightOptionsComponent {

  @Input() saving: boolean;
  @Input() isTemplate: boolean;

  constructor(
    private modalService: NgbModal,
    private importService: ImportService,
    private documentService: DeckingDocumentService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    protected override localizationService: LocalizationService,
    private deckingSubstitutionDefaultFactoryService: DeckingSubstitutionDefaultFactoryService,
    protected override elementRef: ElementRef<HTMLElement>) {
      super(localizationService, elementRef);
  }

  public openExportModal() {
    const modalRef = this.modalService.open(ExportSubstitutionComponent, { size: 'md' });
    modalRef.componentInstance.name = 'exportSubstitution';
  }

  public async duplicateDesign() {
    const designId = this.deckingSubstitutionService.getCurrentSubstitution().id;
    const projectId = this.deckingSubstitutionService.document.projectId;
    const designName = this.deckingSubstitutionDefaultFactoryService.createDeckingDesignName(projectId, true);
    const res = await this.deckingSubstitutionService.copySubstitution(designId, designName, projectId);
    const url = DeckingUrlPath.mainDecking + res.substitutionId;
    window.open(url, '_blank');
  }

  protected override async import(projectSubstitution: File | Blob, name?: string) {
    const currentProject = Object.values(this.documentService.projectsFlat).find((proj) => proj.id == this.deckingSubstitutionService.document.projectId) ?? this.documentService.draftsProject;
    this.importService.importedFromDesign = true;
    try {
      await this.importService.import(currentProject, undefined, projectSubstitution, name, true);
    } finally {
      this.importService.importedFromDesign = false;
    }
  }
}
