import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import {
    DesignExternalMetaData, DocumentAccessMode, DocumentServiceInjected, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';
import { BaseDesign } from '../../entities/decking-design/base-design';

export abstract class DeckingDocumentBaseService extends DocumentServiceInjected {
    public abstract get designsFlat(): Record<string, IDesignListItem>;

    public abstract getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>>;
    public abstract openNewSessionForDesign(designId: string): string;

    public abstract openDesignExclusiveDecking(project: IBaseDesign): Promise<IBaseDesign>;
    public abstract addDesignDecking(projectId: string, deckingDesign: BaseDesign, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItem>;
    public abstract getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any>;


    // To be modularized
    public abstract updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: any, metadataOverride: DesignExternalMetaData, displayDesign: IDetailedDisplayDesign, unlock?: boolean, exclusiveLock?: boolean, documentAccessMode?: DocumentAccessMode): Promise<void>;
    public abstract updateDocumentDesignContent(document: IDesignListItem, base64Content: string, unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void>;
}
