import { Injectable } from '@angular/core';
import { Design as DesignCommon, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DesignExternalMetaData, DocumentAccessMode, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';
import { getMetaDataFromDesign } from 'src/decking/entities/design-external-metadata';
import { DesignTypeId } from 'src/decking/entities/enums/design-types';
import { BaseDesign } from '../../entities/decking-design/base-design';
import { ModalService } from '../external/modal.service';
import { DeckingDocumentBaseService } from './decking-document-base.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingDocumentService extends DeckingDocumentBaseService {
    protected baseServiceDecking!: DeckingDocumentBaseService;


    constructor(
        private readonly modalService: ModalService
    ) {
        super();
    }


    public get designsFlat(): Record<string, IDesignListItem> {
        return this.baseServiceDecking.designsFlat;
    }

    public override setBaseService(baseService: DeckingDocumentBaseService) {
        this.baseServiceDecking = baseService;

        super.setBaseService(baseService);

        this.getDocumentThumbnails = baseService.getDocumentThumbnails.bind(baseService);
        this.openNewSessionForDesign = baseService.openNewSessionForDesign.bind(baseService);


        // To be modularized
        this.updateDesignWithNewContent = baseService.updateDesignWithNewContent.bind(baseService);
        this.updateDocumentDesignContent = baseService.updateDocumentDesignContent.bind(baseService);
    }

    public async openDesignExclusiveDecking(design: IBaseDesign): Promise<IBaseDesign> {
        return this.openDesignExclusiveBase<IBaseDesign>(design, undefined, (design: IBaseDesign) => design);
    }

    public async addDesignDecking(
        projectId: string,
        deckingDesign: BaseDesign,
        canGenerateUniqueName: boolean,
        ignoreConflict: boolean
    ): Promise<IDesignListItem> {
        return await this.addDesignBase({
            projectId,

            designType: DesignTypeId.DiaphragmDesign,
            design: undefined as DesignCommon,
            designNameOverride: deckingDesign.name,
            designOverride: deckingDesign,

            canGenerateUniqueName,
            ignoreConflict,

            adjustDesignListItemContents: (newDesign: IDesignListItem, _designCommon: DesignCommon, project: Project) => {
                newDesign.projectName = project.name;
                newDesign.isSharedByMe = project.isSharedByMe;
            },
            getDesignMetadata: (_designType: number, design: object) => {
                return getMetaDataFromDesign(design as BaseDesign);
            },
            getDesignObject: () => {
                return deckingDesign;
            }
        });
    }

    public async getDeckingDesignContent(
        designId: string,
        sessionId: string,
        documentId: string,
        isLock: boolean
    ): Promise<any> {
        return await this.getDesignContentBase<any>({
            designId,
            sessionId,
            exclusive: isLock,

            documentId,
            handleDocumentLocked: async (documentInfo: IDesignListItem) => {
                this.modalService.openDocumentInUseWarning(documentInfo.lockedUser);
            },
            handleNonLockedStatusErrors: false
        });
    }


    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getDocumentThumbnails(_documentIds: string[]): Promise<Record<string, string>> {
        return {} as Promise<Record<string, string>>;
    }

    public openNewSessionForDesign(_designId: string): string {
        return null;
    }


    // To be modularized
    public updateDesignWithNewContent(_designId: string, _projectId: string, _designName: string, _contentOverride: any, _metadataOverride: DesignExternalMetaData, _displayDesign: IDetailedDisplayDesign, _unlock?: boolean, _exclusiveLock?: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void> {
        return {} as Promise<void>;
    }

    public async updateDocumentDesignContent(_document: IDesignListItem, _base64Content: string, _unlock: boolean, _exclusiveLock: boolean, _documentAccessMode: DocumentAccessMode): Promise<void> {
        return;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
