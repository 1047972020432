import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { combineLatest, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { DeckPanelListItem } from 'src/decking/entities/decking-code-list/code-list/deck-panel-list-item';
import { DeckTypeListItem } from 'src/decking/entities/decking-code-list/code-list/deck-type-list-item';
import { DeckFill } from 'src/decking/entities/decking-code-list/enums/deck-fill';
import { DeckType } from 'src/decking/entities/decking-code-list/enums/deck-type';
import { AreaModel } from 'src/decking/entities/decking-design/area-model';
import { ModalInfo } from 'src/decking/entities/decking-design/modal-info';
import { ZoneModel } from 'src/decking/entities/decking-design/zone-model';
import { CalculationState } from 'src/decking/entities/enums/calculation-state';
import { JOIST_SPACING_TYPE } from 'src/decking/entities/enums/joist-spacing-type';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { DeckingZonesService } from 'src/decking/services/decking-zones/decking-zones.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { ModalService } from 'src/decking/services/external/modal.service';
import { UnitService } from 'src/decking/services/external/unit.service';
import { DeckingCommonAreaPropertiesComponent } from '../decking-areas-common/decking-common-area-properties.component';
import { FeatureVisibilityService } from 'src/decking/services/external/feature-visibility.service';
import { DefinitionOfSidelapConnectorTypes, IDeckingSettingsCollection, IDeckingUserSettings, IUserSettingDocument, IUserSettingRegion } from 'src/decking/entities/settings/decking-user-settings';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { DeckingUserSettingsService } from 'src/decking/services/decking-user-settings/user-settings.service';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { DesignSettings } from 'src/decking/entities/settings/design-settings';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { SidelapsSpacingSettings } from 'src/decking/entities/settings/sidelaps/sidelaps-spacing-settings';
import { SidelapsNumberSettings } from 'src/decking/entities/settings/sidelaps/sidelaps-number-settings';
import { IDeckingDocument } from 'src/decking/entities/decking-design/decking-document';

@Component({
  selector: 'decking-design-area-properties',
  templateUrl: './decking-design-area-properties.component.html',
  styleUrls: ['./decking-design-area-properties.component.scss']
})
export class DeckingDesignAreaPropertiesComponent extends DeckingCommonAreaPropertiesComponent implements OnInit,OnChanges {
  @ViewChild('deckingDesignDefinitionConnection', { static: false }) deckingDesignDefinitionConnection: ElementRef;
  
  readonly ONE_INCH_IN_MM: number = 25.4;
  readonly maxValueSidelapIncrement: number = 20 * this.ONE_INCH_IN_MM;
  readonly maxValueSidelapToInput: number = 100 * this.ONE_INCH_IN_MM;

  @Input()
  public currentArea: AreaModel;
  sidelapConnectorItems: DropdownItem<number>[];
  originalIncrementItems: DropdownItem<number>[];
  regionsItems: DropdownItem<number>[];
  isSidelapEnabled = false;
  showSidelapBySpacing = false;
  selectedSidelapConnector: number;
  deckingDesignSetting: IDeckingUserSettings;
  currentDesign: DeckingDesign;
  originalDeckingSettings: DesignSettings;
  sidelapsSpacingSetting: SidelapsSpacingSettings;
  sidelapsNumberSetting: SidelapsNumberSettings;
  designAreas: AreaModel[];
  public isTemplate: boolean;
  sidelapConnectorsType = DefinitionOfSidelapConnectors;

  constructor(
    public override localization: LocalizationService,
    public override deckingUnitsHelperService: DeckingUnitsHelperService,
    public override unitService: UnitService,
    public override modalService: ModalService,
    public override deckingCodeListService: DeckingCodeListService,
    private deckingDesignService: DeckingDesignService,
    public deckingZoneService: DeckingZonesService,
    public userSettingsService: DeckingUserSettingsService,
    private featureVisibilityService: FeatureVisibilityService,
  ) {
    super(localization, deckingUnitsHelperService, unitService, modalService, deckingCodeListService);
    const dropdownItems = this.buildDropdownItemsCollection(this.userSettingsService.deckingSettingsCollection);
    this.assignItemsToDropdowns(dropdownItems);
    this.deckingDesignSetting = this.userSettingsService.deckingSettings;
    this.selectedSidelapConnector = this.deckingDesignSetting.definitionOfSidelapConnectors.index;
    this.sidelapsSpacingSetting = this.deckingDesignSetting.sidelapsSpacing;
    this.sidelapsNumberSetting = this.deckingDesignSetting.sidelapsNumber;
    this.currentDesign$ = this.deckingDesignService.currentDeckingDesign$.subscribe((data: DeckingDesign) => {
      this.currentDesign = data;
      this.designAreas = data.areas;
    });
  }

  public get isDeckFillNoneSelected() {
    return this.currentArea.deckFill.id == DeckFill.NoFill;
  }

  public get isInsulatingConcreteSelected() {
    return this.currentArea.deckFill.id == DeckFill.InsulatingConcreteWithoutBoard || this.currentArea.deckFill.id == DeckFill.InsulatingConcreteWithBoard;
  }

  public fillThicknessMinValue(): number {
    return this.isDeckFillNoneSelected ? 0 : 50.8;
  }

  ngOnInit(): void {
    this.isSidelapEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_Sidelap');
    this.currentDesignSettings$ = this.deckingDesignService.currentSettings$;
    this.initLengthUnits();
    this.initDropDownItems();
    this.initRadioButtonItems(this.currentArea?.zoneSpacing.value);
    this.initInfoPopUp();

    if (this.isSidelapEnabled) {
      this.designSubscription();
    }
  }

  ngOnChanges(): void {
    this.initRadioButtonItems(this.currentArea?.zoneSpacing.value);
  }

  private initDropDownItems(): void {
    this.initDeckFillDropdownItems();
    this.initCompressiveStrengthItems();
    this.initDeckPanelDropdownItems(this.deckingDesignService.currentArea$);
    this.initPanelTypeDropdownItems(this.deckingDesignService.currentArea$);
    this.initPanelWidthDropDownItems(this.deckingDesignService.currentArea$);
    this.initSupportConstructionDropdownItems();
    this.initDeckTypeRadioButtons(this.currentArea.deckType);
  }

  public initCompressiveStrengthItems(): void {
    combineLatest([
      this.currentDesignSettings$.pipe(
        map(settings => settings.stress.id),
        distinctUntilChanged()
      ),
      this.deckingDesignService.currentArea$.pipe(
        map(currentArea => currentArea.deckFill.id),
        distinctUntilChanged()
      )
    ]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([stressId, deckFillId]) => {
      this.populateCompressiveStrengthDropdownItems(stressId, deckFillId);
    });
  }

  public populateCompressiveStrengthDropdownItems(stressId?: Unit, deckFillId?: DeckFill): void {
    const settingsStressUnit = stressId ?? this.deckingDesignService.getCurrentDesign().settings.stress.id;
    const deckFill = deckFillId ?? this.deckingDesignService.currentArea.deckFill.id;
    const compressiveStrengthUnit = this.deckingUnitsHelperService.getCompressiveStrengthUnit(settingsStressUnit);
    this.compressiveStrengthItems = this.deckingCodeListService.GetCompressiveStrengthDropdownItems(deckFill, compressiveStrengthUnit);
  }

  public emitChanges(isCalculationDirty = true): void {
    if(!this.currentArea.name.value){
      this.currentArea.name.value = this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.Area'+this.deckingDesignService.setCurrentArea(this.index));
    }
    this.updateAreas(isCalculationDirty);
  }

  public emitChangesDeckFill(): void {
    // new default value
    this.currentArea.compressiveStrength = (this.deckingCodeListService.GetDefaultCompressiveStrengthDropdownItem(this.currentArea.deckFill.id));

    // fill thickness default value by region
    const region = this.deckingDesignService.getCurrentDesign().settings.region;
    this.currentArea.fillThickness = {
      value: this.isDeckFillNoneSelected ? 0 : this.deckingCodeListService.GetDefaultConcreteFillThickness(region.index)
    };
    this.updatesWorkLoads();
    this.updateAreas(true);
  }

  public onDeckTypeChanged(deckTypeRadioItem: DeckTypeListItem): void {
    const previousDeckTypeValue = this.currentArea.deckType;
    this.currentArea.deckType = deckTypeRadioItem;
    // Not show the modal if all zones values are the default values
    if (this.checkZoneDefaultValues()) {
      this.applyDeckTypeSelected(deckTypeRadioItem);
      return;
    }

    this.openConfirmModal(this.deckTypeChangedModalInfo,
      this.applyDeckTypeSelected.bind(this),
      this.resetDeckTypeSelected.bind(this),
      deckTypeRadioItem,
      previousDeckTypeValue
    );
  }

  public onDeckPanelSelected(deckPanelDropItem: DeckPanelListItem): void {
    // Not show the modal if don't change the DeckPanel
    if (this.currentArea.deckPanel.id == deckPanelDropItem.id) {
      return;
    }

    const previousDeckPanelValue = this.currentArea.deckPanel;
    this.currentArea.deckPanel = deckPanelDropItem;
    // Not show the modal if all zones values are the default values
    if (this.checkZoneDefaultValues()) {
      this.applyDeckPanelSelected(deckPanelDropItem);
      return;
    }

    this.openConfirmModal({
      id: 'confirm-edit-area-deckPanel',
      title: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.Title'),
      message: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.Message'),
      confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.ConfirmButtonText'),
      cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.CancelButtonText'),
    } as ModalInfo,
      this.applyDeckPanelSelected.bind(this),
      this.resetDeckPanelSelected.bind(this),
      deckPanelDropItem,
      previousDeckPanelValue
    );
  }

  checkZoneDefaultValues(): boolean {
    const currentSettings = this.deckingDesignService.getCurrentDesign().settings;
    const zoneDefault: ZoneModel = this.deckingZoneService.getDefaultZone(currentSettings);
    let defaultValues = true;

    this.currentArea.zones.forEach(zone => {
      if (zone.deckGauge != zoneDefault.deckGauge ||
        zone.pattern != zoneDefault.pattern ||
        zone.frameFastener != zoneDefault.frameFastener ||
        zone.sidelapConnector != zoneDefault.sidelapConnector ||
        zone.side != zoneDefault.side
      ) {
        defaultValues = false;
      }
    });

    return defaultValues;
  }

  applyDeckTypeSelected(deckTypeRadioItem: DeckTypeListItem) {
    this.currentArea.deckType = deckTypeRadioItem;

    // new default value
    const defaltDeckPanelDropdownItem = (this.deckingCodeListService.GetDefaultDeckPanelDropdownItem(this.currentArea.deckType.id));
    this.currentArea.deckPanel = defaltDeckPanelDropdownItem;
    this.currentArea.fu = { value: defaltDeckPanelDropdownItem.fu };
    this.currentArea.fy = { value: defaltDeckPanelDropdownItem.fy };

    // resetting zones
    this.resetZones();

    // logic for an area after its deck panel changed.
    this.deckingDesignService.updatePanel(this.currentArea);
  }

  resetDeckTypeSelected(previousDeckTypeValue: DeckTypeListItem) {
    this.currentArea.deckType = previousDeckTypeValue;
  }

  applyDeckPanelSelected(deckPanelDropItem: DeckPanelListItem) {
    // setting the new deckPanel value after user confirmation
    this.currentArea.deckPanel = deckPanelDropItem;
    this.currentArea.fy = { value: deckPanelDropItem.fy };
    this.currentArea.fu = { value: deckPanelDropItem.fu };

    // resetting zones
    this.resetZones();

    // logic for an area after its deck panel changed.
    this.deckingDesignService.updatePanel(this.currentArea);
  }

  resetDeckPanelSelected(previousDeckPanelValue: DeckPanelListItem) {
    this.currentArea.deckPanel = previousDeckPanelValue;
  }

  onPanelTypeSelected() {
    this.deckingDesignService.updatePanelType(this.currentArea);
  }

  onPanelWidthSelected() {
    this.deckingDesignService.updatePanelWidth(this.currentArea);
  }

  public joistSpacingControlSelectedValueChange(selectedValue: JOIST_SPACING_TYPE) {
    this.currentArea.zoneSpacing = { value: selectedValue === JOIST_SPACING_TYPE.Zone };
    this.updateAreas(true);
  }

  private updateAreas(isCalculationDirty: boolean) {
    this.deckingDesignService.updateCurrentArea(this.currentArea, isCalculationDirty, false);
  }

  private updatesWorkLoads() {
    if(!this?.currentArea?.zones?.length) return;
    const zonesCount = this.currentArea.zones.length;
    const defaultZone = this.deckingZoneService.getDefaultZone(this.deckingDesignService.getCurrentDesign().settings);
    if(this.currentArea.deckType.id === DeckType.ConcreteFilledDeck && this.currentArea.deckFill.id !== DeckFill.NoFill) {
      for (let i = 0; i < zonesCount; i++) {
        this.currentArea.zones[i].w =  defaultZone.w;
      }
    }
  }

  private resetZones(): void {
    const zonesCount = this.currentArea.zones.length;
    const defaultZone = this.deckingZoneService.getDefaultZone(this.deckingDesignService.getCurrentDesign().settings);
    for (let i = 0; i < zonesCount; i++) {
      this.currentArea.zones[i].deckGauge = defaultZone.deckGauge;
      this.currentArea.zones[i].frameFastener = defaultZone.frameFastener;
      this.currentArea.zones[i].sidelapConnector = defaultZone.sidelapConnector;
      this.currentArea.zones[i].pattern = defaultZone.pattern;
      this.currentArea.zones[i].side = defaultZone.side;
      this.currentArea.zones[i].w = (this.currentArea.deckType.id  === DeckType.SteelroofDeck  || (this.currentArea.deckType.id ===  DeckType.ConcreteFilledDeck && this.currentArea.deckFill.id === DeckFill.NoFill) )  ?  this.currentArea.zones[i].w : defaultZone.w;
      this.currentArea.zones[i].calculationState = CalculationState.Empty;
      this.currentArea.zones[i].alternatives = [];
    }
  }

  private buildDropdownItemsCollection(data: IDeckingSettingsCollection): { [name: string]: DropdownItem<number>[] } {
    try {
      const settings: { [name: string]: DropdownItem<number>[] } = {};
      settings['region'] = this.MapRegionsItemsForSidelap(data.region);
      settings['definitionOfSidelapConnectors'] = this.MapDropDownItemsForSidelap(data.definitionOfSidelapConnectors);
      settings['sidelapConnectorSpacingIncrement'] = this.MapDropDownItemsForSidelap(data.sidelapConnectorSpacingIncrement);
      
      return settings;
    } catch (ex: any) {
      console.error('Error while building dropdown items', ex);
      throw ex;
    }
  }

  private MapRegionsItemsForSidelap(regions: IUserSettingRegion[]): DropdownItem<number>[] {
    if (regions) {
      return regions.map(regions => ({
        text: regions.value,
        value: regions.index
      }) as DropdownItem<number>);
    }
    return null;
  }

  private MapDropDownItemsForSidelap(dropdownItems: IUserSettingDocument[]): DropdownItem<number>[] {
    if (dropdownItems) {
      return dropdownItems.map(item => ({
        text: item.value,
        value: item.index,
        level: item.value
      }) as DropdownItem<number>);
    }
    return null;
  }

  private assignItemsToDropdowns(data: { [name: string]: DropdownItem<number>[] }): void {
    this.sidelapConnectorItems = data['definitionOfSidelapConnectors'];
    this.regionsItems = data['region'];
    this.originalIncrementItems = data['sidelapConnectorSpacingIncrement'];
  }

  public get SidelapConnectorTypes() {
    return DefinitionOfSidelapConnectorTypes;
  }

  public designSubscription(): void {
    this.isTemplate$ = this.deckingDesignService.currentDeckingDesign$.pipe(map(design => design.isTemplate), distinctUntilChanged());
    this._isTemplateSubscription = this.isTemplate$.subscribe((data) => {this.isTemplate = data != null || undefined ? data.valueOf() : false;});
  }

  public onDesignDefinitionOfSidelapConnectorChanged(id: number): void {
    // Not show the modal if don't change the Sidelap connection
    if (this.currentArea.definitionOfSidelapConnectors.id == id) {
      return;
    }

    // check if sidelap value is NULL for all the zones, then only show the pop up otherwise return
    if(this.currentArea.zones.every(zone => zone.side === null)){
      this.applySidelapConnectorSelected(id);
      return;
    }

    const previousSidelapConnectorValue = this.currentArea.definitionOfSidelapConnectors;
    this.selectedSidelapConnector = id;
    // Not show the modal if all zones values are the default values
    if (this.checkZoneDefaultValues()) {
      this.applySidelapConnectorSelected(id);
      return;
    }

    if(id == DefinitionOfSidelapConnectors.ByConnectionSpacing) {
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[1].textContent = this.sidelapConnectorItems[0].text;
    } else {
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[1].textContent = this.sidelapConnectorItems[1].text;
    }

    this.openConfirmModal({
      id: 'confirm-edit-area-sidelapConnection',
      title: this.localization.getString('Agito.Hilti.Profis3.Decking.SidelapConnectionChangeConfirmationModal.Title'),
      message: this.localization.getString('Agito.Hilti.Profis3.Decking.SidelapConnectionChangeConfirmationModal.Message'),
      confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.SidelapConnectionChangeConfirmationModal.ConfirmButtonText'),
      cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.SidelapConnectionChangeConfirmationModal.CancelButtonText'),
    } as ModalInfo,
      this.applySidelapConnectorSelected.bind(this),
      this.resetSidelapConnectorSelected.bind(this),
      id,
      previousSidelapConnectorValue
    );
  }

  private applySidelapConnectorSelected(id: number) {
		// setting the new sidelap connection value after user confirmation
		// Here on change of this update respective Area fields only
		let isDefinitionOfSidelapConnectors = false;
		const sidelapConnector = this.userSettingsService.deckingSettingsCollection.definitionOfSidelapConnectors.filter(e => e.index == id)[0];
		this.showSidelapBySpacing = (id == this.SidelapConnectorTypes.connector1);
		isDefinitionOfSidelapConnectors = (this.currentArea.definitionOfSidelapConnectors.id != id);

		// update designService call for current area
		if (isDefinitionOfSidelapConnectors) {
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[1].textContent = sidelapConnector.value;
      if(this.showSidelapBySpacing){
      this.currentArea.definitionOfSidelapConnectors = { id: DefinitionOfSidelapConnectors.ByConnectionSpacing, value: sidelapConnector.value, index: sidelapConnector.index };
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[1].classList.remove('selected');
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[0].classList.add('selected');
      } else {
      this.currentArea.definitionOfSidelapConnectors = { id: DefinitionOfSidelapConnectors.ByNumberOfConnections, value: sidelapConnector.value, index: sidelapConnector.index };
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[0].classList.remove('selected');
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[1].classList.add('selected');
      }
      this.currentArea.zones.forEach(zone => {
      zone.side = null;
      });
      this.currentArea.zones.forEach(zone => {
        zone.alternatives = [];
      });
      this.updateAreas(true);
		}
	}

  private resetSidelapConnectorSelected(previousSidelapConnectorValue: IDeckingDocument<DefinitionOfSidelapConnectors, string>) {
    this.currentArea.definitionOfSidelapConnectors = previousSidelapConnectorValue;
    this.selectedSidelapConnector = previousSidelapConnectorValue.id;

    this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[1].textContent = previousSidelapConnectorValue.value;
    if(previousSidelapConnectorValue.index == DefinitionOfSidelapConnectors.ByConnectionSpacing){
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[1].classList.remove('selected');
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[0].classList.add('selected');
    } else if(previousSidelapConnectorValue.index == DefinitionOfSidelapConnectors.ByNumberOfConnections){
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[0].classList.remove('selected');
      this.deckingDesignDefinitionConnection.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[1].childNodes[1].classList.add('selected');
    }
  }
}
