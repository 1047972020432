import { DesignExternalMetaData } from '@profis-engineering/pe-ui-common/services/document.common';
import { BaseDesign } from './decking-design/base-design';

/**
 * Read the design meta-data from deckingDesign entity for storage in document service
 */
export function getMetaDataFromDesign(deckingProject: BaseDesign): DesignExternalMetaData {
    const ret: DesignExternalMetaData = {
        id: deckingProject.id,
        name: deckingProject.name,
        region: deckingProject.settings.region.id,
        standard: 0,
        designMethod: deckingProject.settings.designMethod.id,
        productName: '',
        designType: 106,
        approvalNumber: ''
    };
    return ret;
}
