import { BaseAreaModel } from './../../../entities/decking-design/area-model';
import { DeckingCodeListService } from '../../decking-code-list/decking-code-list.service';
import { DeckingTabsWrapperService } from '../../decking-tabs-wrapper/decking-tabs-wrapper.service';
import { v4 as uuidv4 } from 'uuid';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { DeckType } from './../../../entities/decking-code-list/enums/deck-type';
import { DeckFill } from './../../../entities/decking-code-list/enums/deck-fill';

export class BaseAreaFactory {
    constructor(
      protected deckingTabsWrapperService: DeckingTabsWrapperService,
      protected deckingCodeListService: DeckingCodeListService
    ) { }

    protected getDefaultBaseArea(currentAreasCount: number, designSettings: DesignSettings): BaseAreaModel {
        const defaultPanel = this.deckingCodeListService.GetDefaultDeckPanelDropdownItem(DeckType.SteelroofDeck);
        const defaultPanelType = this.deckingCodeListService.GetDefaultPanelTypeDropdownItem(defaultPanel.id);
        const region = designSettings.region.index;
        return {
            id: uuidv4(),
            eTag: uuidv4(),
            name: {
                value: this.deckingTabsWrapperService.DefaultAreaTitle(currentAreasCount),
            },
            deckType: this.deckingCodeListService.GetDefaultDeckTypeRadioItem(),
            deckFill: this.deckingCodeListService.GetDefaultDeckFillDropdownItem(),
            compressiveStrength: this.deckingCodeListService.GetDefaultCompressiveStrengthDropdownItem(DeckFill.InsulatingConcreteWithBoard),
            fillThickness: {
                value: this.deckingCodeListService.GetDefaultConcreteFillThickness(region)
            },
            deckPanel: defaultPanel,
            fu: {
                value: defaultPanel.fu
            },
            fy: {
                value: defaultPanel.fy
            },
            panelType: defaultPanelType,
            panelWidth: this.deckingCodeListService.GetDefaultPanelWidthDropdownItem(defaultPanel.id),
            supportConstruction: this.deckingCodeListService.GetDefaultSupportConstructionDropdownItem(),
            zoneSpacing: {
                value: false
            },
            beam: {
                spacing: {
                    value: this.deckingCodeListService.GetDefaultJoistBeamSpacing(region)
                },
                thickness: {
                    value: this.deckingCodeListService.GetDefaultJoistBeamThickness(region)
                }
            },
            isDirty: false,
            definitionOfSidelapConnectors: designSettings.definitionOfSidelapConnectors,
            sidelapsSpacingSettings: designSettings.sidelapsSpacingSettings,
            sidelapsNumberSettings: designSettings.sidelapsNumberSettings
        };
    }
}