<div id="decking-center-container">
    <div id="decking-center-top-container">
        <div id="decking-center-top-left-container">
            <div class="decking-center-top-left-section">
                <app-undo-redo
                    [redoDisabled]="(canRedo$ | async) === false || (saving$ | async)"
                    [undoDisabled]="(canUndo$ | async) === false || (saving$ | async)"
                    (undoButtonClick)="undo()"
                    (redoButtonClick)="redo()">
                </app-undo-redo>

                <pe-numeric-text-box
                    SelectTextOnClick
                    id="main-ZoomPercentage"
                    [defaultStepperValue]="0"
                    [value]="currentZoomPercentage"
                    (valueChange)="zoomPercentageChanged($any($event).detail);"
                    [minValue]="0"
                    [maxValue]="100"
                    [updateOnBlur]="true"
                    [required]="true"
                    [precision]="2"
                    [unit]="percentageUnit"
                    class="z-index">
                </pe-numeric-text-box>

                <app-square-icon-button
                    iconSprite="decking-sprite-search"
                    tooltipTranslationKey="Agito.Hilti.Profis3.Main.ZoomToFit"
                    width="40px"
                    class="z-index"
                    (buttonClick)="fitToScreen()">
                </app-square-icon-button>

                <app-square-icon-button
                    iconSprite="decking-sprite-center"
                    tooltipTranslationKey="Agito.Hilti.Profis3.Main.ResetCamera"
                    width="40px"
                    class="z-index"
                    (buttonClick)="resetCamera()">
                </app-square-icon-button>

                <div class="button-group" role="group" ngbDropdown autoClose="outside">
                    <app-square-icon-button
                        iconSprite="decking-sprite-view"
                        tooltipTranslationKey="Agito.Hilti.Profis3.Main.ShowHideElements"
                        [disabled]="(saving$ | async)"
                        ngbDropdownToggle
                        width="40px"
                        class="z-index">
                    </app-square-icon-button>

                    <div class="content-controls" id="display-options-dropdown" ngbDropdownMenu>
                        <pe-checkbox-button
                            class="control"
                            [items]="settingsCheckbox.items"
                            [selectedValues]="settingsCheckbox.selectedValues"
                            (itemToggle)="settingsCheckboxItemToggle()"
                            [disabled]="isSettings3dLoading">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>
            <div class="decking-center-top-left-section">
                <div class="dropdown-group" role="group" ngbDropdown autoClose="outside">
                    <pe-dropdown
                        id="diaphragmDesign-areas"
                        class="control decking-center-top-left-dropdown"
                        [items]="designAreaItems"
                        [selectedValue]="designAreaItems[currentAreaIndex].value"
                        (selectedValueChange)="designAreaItems[currentAreaIndex]=$any($event).detail;onSelectedAreaChange($any($event).detail)">
                    </pe-dropdown>
                    <pe-dropdown
                        id="diaphragmDesign-zones"
                        class="control decking-center-top-left-dropdown"
                        [items]="designCurrentAreaZoneItems"
                        [selectedValue]="designCurrentAreaZoneItems[currentZoneIndex]?.value"
                        (selectedValueChange)="designCurrentAreaZoneItems[currentZoneIndex]=$any($event).detail;onSelectedZoneChange($any($event).detail)">
                    </pe-dropdown>
                </div>

                <pe-loading-button
                    id="decking-loading"
                    loading="true"
                    *ngIf="saving$ | async">
                </pe-loading-button>
            </div>
        </div>
        <div id="decking-floating-dropdowns">
            <design-product-selector
                [currentArea]="currentArea$ | async"
                [currentDeckingZone]="zoneSelected$ | async">
            </design-product-selector>
        </div>
    </div>

    <div id="decking-center-middle-container" #canvasContainer [ngClass]="cssClassCanvas"></div>

    <div id="decking-center-bottom-container">
        <app-square-icon-button
            [iconSprite]="designerLayoutService.leftColumnVisible ? 'decking-sprite-arrow-left-medium' : 'decking-sprite-arrow-right-medium'"
            (buttonClick)="toggleLeftColumnVisibility()"
            tooltipTranslationKey="Agito.Hilti.Profis3.Main.CollapseMenu"
            width="32px"
            height="32px"
            class="z-index">
        </app-square-icon-button>

        <app-square-icon-button
            [iconSprite]="designerLayoutService.rightColumnVisible ? 'decking-sprite-arrow-right-medium' : 'decking-sprite-arrow-left-medium'"
            (buttonClick)="toggleRightColumnVisibility()"
            tooltipTranslationKey="Agito.Hilti.Profis3.Main.CollapseMenu"
            width="32px"
            height="32px"
            class="z-index">
        </app-square-icon-button>
    </div>
</div>

<div id="helperContainer" #helperContainer style="display: none; position: absolute; z-index:9000">
        <decking-bootstrap-dropdown
            class="longDropdown"
            [id]="frameFastenerDropDownId"
            [items]="$any(frameItems$) | async"
            [disabled]="(saving$ | async)"
            [(selectedValue)]="$any(currentZone).frameFastener"
            (selectedValueChange)="valueSelected($any(currentZone).frameFastener)"
            [container]="'body'"
            [placement]="'top-left'"
        >
        </decking-bootstrap-dropdown>
        <decking-bootstrap-dropdown
            class="longDropdown"
            [id]="sidelapDropDownId"
            [items]="$any(sidelapItems$) | async"
            [disabled]="(saving$ | async)"
            [(selectedValue)]="$any(currentZone).sidelapConnector"
            (selectedValueChange)="valueSelected($any(currentZone).sidelapConnector)"
            [container]="'body'"
            [placement]="'top-left'"
        >
        </decking-bootstrap-dropdown>
        <decking-bootstrap-dropdown
            class="shortDropdown"
            [id]="deckGaugeDropDownId"
            [items]="$any(deckGaugeItems$) | async"
            [disabled]="(saving$ | async)"
            [(selectedValue)]="$any(currentZone).deckGauge"
            (selectedValueChange)="valueSelected($any(currentZone).deckGauge)"
            [container]="'body'"
            [placement]="'top-left'"
        >
        </decking-bootstrap-dropdown>
        <decking-bootstrap-dropdown
            class="shortDropdown"
            [id]="panelWidthDropDownId"
            [items]="$any(panelWidthItems$) | async"
            [disabled]="(saving$ | async)"
            [(selectedValue)]="currentArea.panelWidth"
            (selectedValueChange)="onPanelWidthSelected()"
            [container]="'body'"
            [placement]="'top-left'"
        >
        </decking-bootstrap-dropdown>
        <td class="input-cell">
            <pe-numeric-text-box
            SelectTextOnClick
            [id]="shearInputTextId"
            [value]="currentZone.q.value"
            (valueChange)="currentZone.q.value=$any($event).detail;valueChanged();"
            [showStepper]="true"
            [updateOnBlur]="true"
            [required]="true"
            [unit]="forcePerLengthUnit$ | async"
            [minValue]="0"
            [maxValue]="87.5633954605"
            [precision]="0"
            [disabled]="(saving$ | async)"
            (keydown.enter)="onEnterKeyDown(shearInputTextId)"
        />
        </td>
        <td class="input-cell">
            <pe-numeric-text-box
            SelectTextOnClick
            [id]="upliftInputTextId"
            [value]="currentZone.w.value"
            (valueChange)="currentZone.w.value=$any($event).detail;valueChanged();"
            [showStepper]="true"
            [updateOnBlur]="true"
            [required]="true"
            [unit]="stressUnit$ | async"
            [minValue]="0"
            [maxValue]="0.07182000000000001"
            [precision]="0"
            [disabled]="(((isConcreteFill$ | async) || (saving$ | async)) && (isDeckFilled$ | async ) === false)"
            (keydown.enter)="onEnterKeyDown(upliftInputTextId)"
        />
        </td>
        <td class="input-cell">
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="shearStiffnessInputTextId"
                [value]="currentZone.g.value"
                (valueChange)="currentZone.g.value=$any($event).detail;valueChanged();"
                [showStepper]="true"
                [updateOnBlur]="true"
                [required]="true"
                [unit]="shearStiffnessUnit$ | async"
                [minValue]="0"
                [maxValue]="35025.367064830236"
                [precision]="1"
                [disabled]="(saving$ | async)"
                (keydown.enter)="onEnterKeyDown(shearStiffnessInputTextId)"
            />
        </td>

        <td class="input-cell">
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="beamSpacingInputTextId"
                [showStepper]="true"
                [value]="currentArea.beam.spacing.value"
                (valueChange)="currentArea.beam.spacing.value=$any($event).detail;updateAreas();"
                [minValue]="304.8"
                [maxValue]="18288"
                [unit]="joistBeamSpacingUnit$ | async"
                [updateOnBlur]="true"
                [required]="true"
                [precision]="2"
                [disabled]="(saving$ | async)"
                (keydown.enter)="onEnterKeyDown(beamSpacingInputTextId)"
            />
        </td>
</div>