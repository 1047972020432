import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import cloneDeep from 'lodash-es/cloneDeep';
import { distinctUntilChanged, map, mergeMap, Observable, Subscription } from 'rxjs';
import { DesignMethodConverter } from 'src/decking/entities/decking-code-list/enums/helpers/design-method-converter';
import { DesignStandardConverter } from 'src/decking/entities/decking-code-list/enums/helpers/design-standard-converter';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { IDeckingSettingsCollection, IUserSettingDocument } from 'src/decking/entities/settings/decking-user-settings';
import { DesignSettings } from 'src/decking/entities/settings/design-settings';
import { SubstitutionSettings } from 'src/decking/entities/settings/substitution-settings';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingUserSettingsService } from 'src/decking/services/decking-user-settings/user-settings.service';
import { FeatureVisibilityService } from 'src/decking/services/external/feature-visibility.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { LoggerService } from 'src/decking/services/external/logger.service';

type CombinedDesignSettings = DesignSettings | SubstitutionSettings;

@Component({
    selector: 'design-section-wrapper',
    templateUrl: './design-section-wrapper.component.html',
    styleUrls: ['./design-section-wrapper.component.scss'],
})
export class DesignSectionWrapperComponent implements OnInit, OnDestroy {
    public isTemplate: boolean;
    public isTemplate$: Observable<boolean>;

    // design methods dropdown
    designMethodItems: DropdownItem<number>[];

    // design standard dropdown
    designStandardItems: DropdownItem<number>[];

    isMultiCodeEnabled = false;
    currentDesignSubscription: Subscription;
    currentSubstitutionSubscription: Subscription;
    originalDeckingSettings: DesignSettings;
    internalDeckingSettings: DesignSettings;
    _isTemplateSubscription: Subscription;
    currentDesign$: Subscription;
    currentDesign: DeckingDesign;
    currentSubstitution$: Subscription;
    currentSubstitution: DeckingSubstitution;
    currentDocument$: Subscription;
    currentDocument: IDesignListItem;

    constructor(public localization: LocalizationService,
        public userSettingsService: DeckingUserSettingsService,
        private featureVisibilityService: FeatureVisibilityService,
        public deckingMainService: DeckingMainService,
        private loggerService: LoggerService,
        private deckingDesignService: DeckingDesignService,
        private deckingSubstitutionService: DeckingSubstitutionService) {
        const dropdownItems = this.buildDropdownItemsCollection(this.userSettingsService.deckingSettingsCollection);
        this.assignItemsToDropdowns(dropdownItems);
    }

    ngOnInit(): void {
      this.isMultiCodeEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_MultiCodes');
      
      switch (this.deckingMainService.getSelectedModeType()) {
        case DeckingDesignModeType.DesignMode:
            this.designSubscription();
            break;
        case DeckingDesignModeType.SubstitutionBuilderMode:
            this.substitutionSubscription();
            break;
        default:
            this.loggerService.logServiceError(`Invalid design Type : ${this.deckingMainService.getSelectedModeType()}`, 'DesignSectionWrapperComponent', 'ngOnInit');
            break;
      }
    }

    designSubscription(): void {
      this.isTemplate$ = this.deckingDesignService.currentDeckingDesign$.pipe(map(design => design.isTemplate), distinctUntilChanged());
      this._isTemplateSubscription = this.isTemplate$.subscribe((data) => {this.isTemplate = data != null || undefined ? data.valueOf() : false;});
      this.currentDesign$ = this.deckingDesignService.currentDeckingDesign$.subscribe((data: DeckingDesign) => {
        this.currentDesign = data;
      });
      this.currentDocument$ = this.deckingDesignService.currentDocument$.subscribe((data: IDesignListItem) => {
        this.currentDocument = data;
      });

      this.currentDesignSubscription = this.deckingDesignService.currentSettings$.pipe(mergeMap(async (settings: DesignSettings) => {
        // cloning decking settings as an internal state for current component
        this.originalDeckingSettings = settings;
        const tempSettings = settings;
        this.setInternalDesignSettings(tempSettings);
      })).subscribe();
    }

    substitutionSubscription(): void {
      this.isTemplate = false;
      this.currentSubstitution$ = this.deckingSubstitutionService.currentDeckingSubstitution$.subscribe((data: DeckingSubstitution) => {
        this.currentSubstitution = data;
      });
      this.currentDocument$ = this.deckingSubstitutionService.currentDocument$.subscribe((data: IDesignListItem) => {
        this.currentDocument = data;
      });

      this.currentSubstitutionSubscription = this.deckingSubstitutionService.currentSettings$.pipe(mergeMap(async (settings: SubstitutionSettings) => {
          // cloning decking substitution settings as an internal state for the current component
          this.originalDeckingSettings = settings;
          const tempSettings = settings;
  
          this.setInternalDesignSettings(tempSettings);
        })
      ).subscribe();
    }

    getDesignMethod(selectedItem: number) {
      const selectedItemValue = this.designMethodItems.find(item => item.value === selectedItem);
      this.internalDeckingSettings.designMethod = {
        id: DesignMethodConverter.getDesignMethodFromString(selectedItemValue.text),
        index: selectedItemValue.value,
        value: selectedItemValue.text
      };

      this.updateSettings('getDesignMethod');
    }

    getDesignStandard(selectedItem: number) {
      const selectedItemValue = this.designStandardItems.find(item => item.value === selectedItem);
      this.internalDeckingSettings.designStandard = {
        id: DesignStandardConverter.getDesignStandardFromString(selectedItemValue.text),
        index: selectedItemValue.value,
        value: selectedItemValue.text
      };

      this.updateSettings('getDesignStandard');
    }

    private setInternalDesignSettings(settings: CombinedDesignSettings): void {
      this.internalDeckingSettings = cloneDeep(settings);
    }

    private buildDropdownItemsCollection(data: IDeckingSettingsCollection): { [name: string]: DropdownItem<number>[] } {
        try {
          const settings: { [name: string]: DropdownItem<number>[] } = {};
          settings['designStandard'] = this.MapDropDownItems(data.designStandard);
          settings['designMethod'] = this.MapDropDownItems(data.designMethod);
          return settings;
        } catch (ex: any) {
          console.error('Error while building dropdown items', ex);
          throw ex;
        }
    }

    /**
   * Assign every dropdown items to be bind to every dropdown component.
   * @param data - the data to be used to build the dropdown items collection
   */
    private assignItemsToDropdowns(data: { [name: string]: DropdownItem<number>[] }): void {
        this.designMethodItems = data['designMethod'];
        this.designStandardItems = data['designStandard'];
    }

    private MapDropDownItems(items: IUserSettingDocument[]): DropdownItem<number>[] {
        if (items) {
          return items.map(item => ({
            text: item.value,
            value: item.index,
            level: item.value
          }) as DropdownItem<number>);
        }
        return null;
    }

    private updateSettings(methodName: string) {
      const isCalculationRequired = this.isCalculationRequired();
      switch (this.deckingMainService.getSelectedModeType()) {
        case DeckingDesignModeType.DesignMode:
            this.deckingDesignService.updateDesignSettings(this.internalDeckingSettings, '', this.currentDocument?.designName, isCalculationRequired);
            break;
        case DeckingDesignModeType.SubstitutionBuilderMode:
          this.deckingSubstitutionService.updateSubstitutionSettings(this.internalDeckingSettings, '', this.currentDocument?.designName, isCalculationRequired);
            break;
        default:
            this.loggerService.logServiceError(`Invalid design Type : ${this.deckingMainService.getSelectedModeType()}`, 'DesignSectionWrapperComponent', methodName);
            break;
      }
    }

    private isCalculationRequired(): boolean {
      const isDesignMethodDifferent = this.internalDeckingSettings.designMethod.id !== this.originalDeckingSettings.designMethod.id;
      const isDesignStandardDifferent = this.internalDeckingSettings.designStandard.id !== this.originalDeckingSettings.designStandard.id;
  
      return isDesignMethodDifferent || isDesignStandardDifferent;
    }

    ngOnDestroy(): void {
      this._isTemplateSubscription?.unsubscribe();
      this.currentDesignSubscription?.unsubscribe();
      this.currentSubstitutionSubscription?.unsubscribe();
      this.currentDesign$?.unsubscribe();
      this.currentSubstitution$?.unsubscribe();
      this.currentDocument$?.unsubscribe();
    }
}