import { Component, ElementRef, OnInit } from '@angular/core';
import { DeckingZonesAlternativesService } from '../../../../services/decking-zones/alternatives/decking-zones-alternatives.service';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { DeckingUnitsHelperService } from '../../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingCodeListService } from '../../../../services/decking-code-list/decking-code-list.service';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { includeSprites } from '../.././../../sprites';
import { DefinitionOfSidelapConnectors } from './../../../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { CompareZoneAlternativesCommonComponent } from './compare-zone-alternatives-common.component';
import cloneDeep from 'lodash-es/cloneDeep';
import { ZoneModel } from 'src/decking/entities/decking-design/zone-model';

@Component({
    templateUrl: './compare-zone-alternatives.component.html',
    styleUrls: ['./compare-zone-alternatives.component.scss']
})
export class CompareZoneAlternativesComponent extends CompareZoneAlternativesCommonComponent implements OnInit {
    currentZone: ZoneModel;

    constructor(
        private deckingDesignService: DeckingDesignService,
        private deckingZonesAlternativesService: DeckingZonesAlternativesService,
        protected override deckingUnitsHelperService: DeckingUnitsHelperService,
        protected override deckingCodeListService: DeckingCodeListService,
        protected elementRef: ElementRef<HTMLElement>
    ) {
        super(deckingUnitsHelperService, deckingCodeListService);
    }

    async ngOnInit(): Promise<void> {
        includeSprites(this.elementRef.nativeElement,
            'sprite-checkbox'
        );
        this.isBySpacing$ = this.deckingDesignService.currentArea$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.currentZone = this.deckingDesignService.currentZone;
        this.currentSettings$ = this.deckingDesignService.currentSettings$;
        this.isImperialUnit$ = this.currentSettings$.pipe(map(s => s.length.id),
            distinctUntilChanged(),
            map((unitValue: Unit) => {
                return !this.deckingUnitsHelperService.isInternationalSystemUnit(unitValue);
            }));
        this.initUnits();
        await this.loadAlternatives(this.currentZone.alternatives);
    }

    public includeInReport() {
        if (this.selectedAlternatives.size <= this.maxAllowedSelection) {
            this.isSubmitting = true;
            this.deckingDesignService.updateCurrentZone(this.currentZone);
            this.deckingZonesAlternativesService.setAlternatives(this.deckingDesignService.getCurrentDesign());
            this.close();
        }
    }
    
    protected override initLengthUnit(): void { 
        this.lengthUnit$ = this.deckingDesignService.currentSettings$.pipe(
            map(s => s.length.id),
            distinctUntilChanged(),
        );
    }

    protected override async loadAlternativesFromBackend(): Promise<void> {
        const currentDesign = cloneDeep(this.deckingDesignService.getCurrentDesign());
        const zoneAlternatives = await this.deckingZonesAlternativesService.getAlternatives(currentDesign);
        this.alternatives = zoneAlternatives;
        this.currentZone.alternatives = zoneAlternatives;
    }
}
