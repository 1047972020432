import { Component,  ElementRef,  OnInit} from '@angular/core';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { DeckingCommonZonesHeaderComponent } from './../../../../../components/decking-main-bottom/decking-zones-common/decking-common-zones-header.component';
import { RelevantLoads } from './../../../../../entities/decking-code-list/enums/relevant-loads';
import { DeckingSubstitutionService } from './../../../../../services/decking-design/decking-substitution.service';
import { LocalizationService } from './../../../../../services/external/localization.service';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from 'src/decking/services/external/unit.service';
import { includeSprites } from 'src/decking/sprites';

@Component({
    selector: '[decking-substitution-zones-header]',
    templateUrl: './decking-substitution-zones-header.component.html',
    styleUrls: ['./decking-substitution-zones-header.component.scss']
})
export class DeckingSubstitutionZonesHeaderComponent extends DeckingCommonZonesHeaderComponent implements OnInit {
    sidelapConnectorTooltip$ : Observable<string>;
    public forcePerLengthUnit: string;
    public stressUnit: string;
    public shearStiffnessUnit: string;

    constructor(private unitService: UnitService,  
        public override localization: LocalizationService,
        public deckingSubstitutionService: DeckingSubstitutionService,
        public elementRef: ElementRef<HTMLElement>
    ) {
        super(localization);
    }   
    getFormattedUnit(unit: UnitType): string {
        return ' ['+ this.unitService.formatUnit(unit) + ']';  
    }
    
    public override ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
        'sprite-info'
      );
        this.isRequiredShearStiffnessSetting$ = this.deckingSubstitutionService.isRequiredShearStiffnessSetting$;
        this.isRequiredUpliftSubmittalSetting$ = this.deckingSubstitutionService.isRequiredUpliftSubmittalSetting$;
        this.isSubstitutionRequiredShearStiffnessSetting$ = this.deckingSubstitutionService.isSubstitutionRequiredShearStiffnessSetting$;
        this.isSubstitutionRequiredUpliftSubmittalSetting$ = this.deckingSubstitutionService.isSubstitutionRequiredUpliftSubmittalSetting$;
        this.isSubstitutionRelevantLoadAtZoneLevelSetting$ = this.deckingSubstitutionService.isSubstitutionRelevantLoadAtZoneLevelSetting$;
        this.designMethod$ = this.deckingSubstitutionService.currentSettings$.pipe(
            map((settings) => {
                return settings.designMethod.value;
            }),
            distinctUntilChanged()
        );
        
        this.sidelapConnectorTooltip$ = this.deckingSubstitutionService.currentArea$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing ?
                'Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.FastenerSpacing' :
                'Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.SideByConnectors'),
            distinctUntilChanged()
        );

       this.deckingSubstitutionService.currentSettings$?.subscribe(x=> {
        this.forcePerLengthUnit = this.getFormattedUnit(x.forcePerLength?.id);
        this.stressUnit = this.getFormattedUnit(x.stress?.id);
        this.shearStiffnessUnit = this.getFormattedUnit(x.shearStiffness?.id);
       });
        super.ngOnInit();
    }

    public setZoneType(relevantLoads: RelevantLoads): void {
        this.deckingSubstitutionService.updateZoneTypeForZones(relevantLoads);
    }
}
