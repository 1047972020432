import { Injectable } from '@angular/core';
import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import { CanvasContext3d } from '@profis-engineering/decking-gl-model/canvas-context-3d';
import { FastenerType, GLEvent, SidelapType, SpacingType } from '@profis-engineering/decking-gl-model/components/interfaces/gl-enums';
import {
  IBeamGLObjectOptions,
  IDeckConcreteGLObjectOptions, IDeckGLObjectOptions,
  IDeckingSceneOptions,
  IFastenerGLObjectOptions,
  IFastenerGenericOptions,
  IFastenerHiltiOptions,
  IGLObjectOptions, IJoistGLObjectOptions,
  ILineGLObjectOptions,
  ISidelapGLObjectOptions,
  ISidelapGenericOptions,
  ISidelapHiltiOptions,
  IWallBracketGLObjectOptions
} from '@profis-engineering/decking-gl-model/components/interfaces/gl-interfaces';
import { DeckingScene } from '@profis-engineering/decking-gl-model/components/scenes/decking-scene';
import { DeckingGlConstants } from '@profis-engineering/decking-gl-model/components/utils/decking-gl-constants';
import { getOrCreateContext3d } from '@profis-engineering/decking-gl-model/context-3d';
import { EventNotifier } from '@profis-engineering/decking-gl-model/external/event-notifier';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { combineLatest, debounceTime, timer } from 'rxjs';

import { FrameFastenerListItem } from './../../entities/decking-code-list/code-list/frame-fastener-list-item';
import { SidelapConnectorListItem } from './../../entities/decking-code-list/code-list/sidelap-connector-list-item';
import { DeckFill } from './../../entities/decking-code-list/enums/deck-fill';
import { DeckType } from './../../entities/decking-code-list/enums/deck-type';
import { DefinitionOfSidelapConnectors } from './../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { SupportConstruction } from './../../entities/decking-code-list/enums/support-construction';
import { AreaModel } from './../../entities/decking-design/area-model';
import { ZoneModel } from './../../entities/decking-design/zone-model';
import { DesignSettings } from './../../entities/settings/design-settings';
import { Decking3dSettingsService } from './../../services/decking-3d-settings/decking-3d-settings.service';
import { DeckingCodeListService } from './../../services/decking-code-list/decking-code-list.service';
import { DeckingDesignService } from './../../services/decking-design/decking-design.service';
import { DeckingDocumentService } from './../../services/decking-document/decking-document.service';
import { DeckingUnitsHelperService } from './../../services/decking-units-helper/decking-units-helper.service';
import { ProductSelectorService } from './../../services/decking-zones/product-selector/product-selector.service';
import { LocalizationService } from './../../services/external/localization.service';
import { UnitService } from './../../services/external/unit.service';
import { interpolateFillThickness, interpolateJoistSpacing } from './functions/interpolation-functions';
import { FeatureVisibilityService } from '../external/feature-visibility.service';
import { DesignTemplateService } from '../external/design-template.service';
import { DeckingMainService } from '../decking-main/decking-main.service';

class GLEventNotifier implements EventNotifier {
  webGLContextLost: () => void;
  webGLContextRestored: () => void;
  fontsLoaded: () => void;
  registerCurrentStateUndoRedoAction: () => void;
  positionsChanged2d: () => void;
  draggingSelectionChanged2d: () => void;
  zoomChange: (zoom: number) => void;
  jsEvent(action: () => void): void {
    action();
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeckingGlModelService {
  // Temporal - needs to be changed for the value from the UI
  readonly supportSpacing = 2540;
  protected glModel: DeckingScene;
  protected options: IDeckingSceneOptions;
  private emitScreenShoot = true;
  private previousSupport = SupportConstruction.BarJoists;
  private glEventNotifier = new GLEventNotifier();
  private settings: DesignSettings;
  private currentPanelPoints: [Vector2[], Vector2[]];
  public pickedMesh: string;

  constructor(
    private productSelectorService: ProductSelectorService,
    private deckingDesignService: DeckingDesignService,
    private documentService: DeckingDocumentService,
    private decking3dSettingsService: Decking3dSettingsService,
    private deckingUnitHelperService: DeckingUnitsHelperService,
    private unitService: UnitService,
    private deckingCodeListService: DeckingCodeListService,
    private localization: LocalizationService,
    private featuresVisibilityService: FeatureVisibilityService,
    private designTemplateService: DesignTemplateService,
    private deckingMainService: DeckingMainService,
  ) {
    this.deckingDesignService.currentSettings$.subscribe((settings: DesignSettings) => {
      this.settings = settings;
    });

    const currentArea$ = this.deckingDesignService.currentArea$;
    const currentZone$ = this.deckingDesignService.currentZone$;
    const settings3d$ = this.decking3dSettingsService.settings3d$;
    const designSettings$ = this.deckingDesignService.currentSettings$;

    combineLatest([currentZone$, currentArea$, settings3d$, designSettings$]).pipe(debounceTime(500)).subscribe(([zone, area, _, designSettings]) => {
      this.updateGLModel(designSettings, area, zone);
    });
  }

  public get isNewHomePage() {
    return this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
  }

  public setZoomChangeEvent(callback: (zoom: number) => void): void {
    this.glEventNotifier.zoomChange = callback;
  }

  public createGLModel3D(currentSettings: DesignSettings, currentArea: AreaModel, currentZone: ZoneModel, glModelId?: string, htmlParent?: HTMLElement): DeckingScene {
    this.resize();

    this.options = this.createGLOptions(currentSettings, currentArea, currentZone, true);
    const context3d = getOrCreateContext3d(glModelId, () => new CanvasContext3d(glModelId, htmlParent));
    this.glModel = new DeckingScene(
      context3d,
      this.options,
      this.glEventNotifier,
      false,
      '#FFFFFF'
    );
    this.glModel.resize();
    return this.glModel;
  }

  public updateGLModel(currentSettings: DesignSettings, currentArea?: AreaModel, currentZone?: ZoneModel): void {
    try {
      if (!this.glModel) {
        return;
      }
      const modifiedObjects = this.getModifiedModels(currentSettings, currentArea, currentZone);
      const modifiedLines: ILineGLObjectOptions[] = [];
      modifiedObjects.forEach(glObject => {
        if (this.isLineOption(glObject)) {
          modifiedLines.push(glObject);
        } else {
          this.glModel.updateGLObject(glObject);
        }
      }
      );

      if (modifiedLines.length > 0) {
        this.glModel.updateGLLines(modifiedLines);
      }

      if (this.emitScreenShoot) {
        // Add timer to take the screenshot, if this is omitted the screenshot could be incomplete
        const imgWidth = this.isNewHomePage ? 190 : 145;
        const imgHeight = this.isNewHomePage ? 120 : 145;
        timer(0).subscribe(() => {
          (async () => {
            const dataImage = await this.glModel.createScreenshot(imgWidth, imgHeight);
            if(this.deckingMainService.getTemplateId()) {
              this.designTemplateService.updateDesignThumbnailImage(this.deckingMainService.getTemplateId(), dataImage, false);
              this.deckingMainService.setTemplateId(null);
            }
            if (this.deckingDesignService.document)
              this.documentService.updateDesignThumbnailImage(this.deckingDesignService.documentId, dataImage, false);
          })();
        });
      }
    }
    catch (e) {
      console.error(e);
      throw e;
    }
  }

  public updateGLLines(currentSettings: DesignSettings, currentArea: AreaModel, currentZone: ZoneModel): void {
    try {
      if (!this.glModel) {
        return;
      }
      this.glModel.updateGLLines(this.buildLineOptions(currentArea, currentZone, currentSettings));
    }
    catch (e) {
      console.error(e);
      throw e;
    }
  }

  public resize(): void {
    setTimeout(() => {
      // canvas is not the correct size yet so we wait
      this.glModel.resize();
    });
  }

  public resetCamera(): void {
    this.glModel.resetCamera();
  }

  public fitToScreen(): void {
    this.glModel.fitSceneToCamera();
  }

  public cameraZoom(percentage: number): void {
    this.glModel.cameraZoom(percentage);
  }

  public dispose(): void {
    this.glModel.dispose();
    this.glModel = null;
  }

  private getModifiedModels(currentSettings: DesignSettings, currentArea?: AreaModel, currentZone?: ZoneModel): IGLObjectOptions[] {
    let modifiedObjects: IGLObjectOptions[] = [];
    const isPanelModified = this.isPanelModified(currentArea, currentZone);

    if (currentArea && this.previousSupport != currentArea.supportConstruction.id) {
      this.previousSupport = currentArea.supportConstruction.id;
      modifiedObjects.push(this.buildSupportOptions(false, currentArea));
      modifiedObjects.push(this.buildPanelOptions(currentArea, currentZone, false)); // update panel to match the end of the support
    }

    if (isPanelModified) {
      modifiedObjects.push(this.buildPanelOptions(currentArea, currentZone, false));
    }

    modifiedObjects = this.isSidelapModified(modifiedObjects, currentArea, currentZone);
    modifiedObjects = this.isJoistBeamSpacingModified(modifiedObjects, currentArea, currentZone);
    modifiedObjects = this.buildFastenerOptions(modifiedObjects, currentArea, currentZone);
    modifiedObjects = modifiedObjects.concat(this.buildLineOptions(currentArea, currentZone, currentSettings));

    return modifiedObjects;
  }

  private isSidelapModified(modifiedObjects: IGLObjectOptions[], currentArea?: AreaModel, currentZone?: ZoneModel): IGLObjectOptions[] {
    if (currentZone && currentArea && this.settings) {
      const spacingType = !currentZone.side || !this.settings || currentArea.definitionOfSidelapConnectors.id == DefinitionOfSidelapConnectors.ByNumberOfConnections ? SpacingType.Connectors : SpacingType.Spacing;
      const spacing = currentZone.side ? currentZone.side.value : 0;
      const sidelapItem = this.getSidelapItem(currentArea, currentZone);

      if (spacing != this.options.sidelap.options.spacing || spacingType != this.options.sidelap.options.spacingType) {
        modifiedObjects.push(this.buildSidelapOptions(false, currentZone, sidelapItem?.value, spacingType));
      }
    }

    return modifiedObjects;
  }

  private isJoistBeamSpacingModified(modifiedObjects: IGLObjectOptions[], currentArea?: AreaModel, currentZone?: ZoneModel): IGLObjectOptions[] {
    const spacingType = !currentZone.side || !this.settings || currentArea.definitionOfSidelapConnectors.id == DefinitionOfSidelapConnectors.ByNumberOfConnections ? SpacingType.Connectors : SpacingType.Spacing;
    if (currentArea?.beam?.spacing?.value) {
      const isSpacingModified = this.options.wallBracket.options.length !== interpolateJoistSpacing(this.getJoistSpacing(currentArea, currentZone)) * 2;

      if (isSpacingModified) {
        modifiedObjects.push(this.buildWallBracketOptions(currentArea, currentZone, false));
        modifiedObjects.push(this.buildPanelOptions(currentArea, currentZone, false));
        modifiedObjects.push(this.buildSidelapOptions(false, currentZone, this.getSidelapItem(currentArea, currentZone)?.value, spacingType));
        modifiedObjects.push(this.buildSupportOptions(false, currentArea));
      }
    }
    return modifiedObjects;
  }

  private createGLOptions(currentSettings: DesignSettings, currentArea: AreaModel, currentZone: ZoneModel, firstLoad = false): IDeckingSceneOptions {
    const sidelapItem = this.getSidelapItem(currentArea, currentZone);
    const fastenerItem = this.getFastenerItem(currentArea, currentZone);
    const sidelapConnectionType = !currentZone.side || !this.settings || currentArea.definitionOfSidelapConnectors.id == DefinitionOfSidelapConnectors.ByNumberOfConnections ? SpacingType.Connectors : SpacingType.Spacing;
    const sidelapOptions = this.buildSidelapOptions(firstLoad, currentZone, sidelapItem?.value, sidelapConnectionType);
    const fastenerBorderOptions = this.buildFastenerBorderOptions(firstLoad, fastenerItem?.value);
    const fastenerMiddleOptions = this.buildFastenerMiddleOptions(firstLoad, fastenerItem?.value);

    return {
      panel: this.buildPanelOptions(currentArea, currentZone, firstLoad),
      wallBracket: this.buildWallBracketOptions(currentArea, currentZone, firstLoad),
      sidelap: sidelapOptions,
      support: this.buildSupportOptions(firstLoad, currentArea),
      fastenersBorder: fastenerBorderOptions,
      fastenersMiddle: fastenerMiddleOptions,
      fastenersVertical: fastenerMiddleOptions,
      lineOptions: this.buildLineOptions(currentArea, currentZone, currentSettings),
      infiniteWall: {
        events: [],
        GlObjectsIds: firstLoad ? [] : this.options.infiniteWall.GlObjectsIds,
        options: {
          length: 1200,
          height: 400,
          depth: 100,
          numberOfArcs: 2,
          arcsDepth: 20
        }
      },
    } as IDeckingSceneOptions;
  }

  private isPanelModified(currentArea: AreaModel, currentZone?: ZoneModel) {

    if (currentArea) {
      const isConcreteFill = currentArea.deckType.id === DeckType.ConcreteFilledDeck;
      const isConcreteFill3D = Object.prototype.hasOwnProperty.call(this.options.panel.options, 'fillThickness');
      if (isConcreteFill !== isConcreteFill3D) {
        return true;
      }

      if (isConcreteFill3D && this.isFillThicknessChanged(currentArea)) {
        return true;
      }

      return this.arePanelPointsChanged(currentArea, currentZone);
    }

    return false;
  }

  private isFillThicknessChanged(currentArea: AreaModel): boolean {
    const concreteFillOptions = this.options.panel as IDeckConcreteGLObjectOptions;
    const fillThickness = interpolateFillThickness(currentArea.fillThickness.value);
    const currenTransparencySettings = this.decking3dSettingsService.settings3d.transparentConcreteFill ? 0.5 : 1;
    if (fillThickness != concreteFillOptions.options.fillThickness || concreteFillOptions.options.transparency != currenTransparencySettings) {
      return true;
    }
    return false;
  }

  private arePanelPointsChanged(currentArea: AreaModel, currentZone?: ZoneModel) {
    if (currentZone) {
      const panelPoints = this.getPanelPoints(currentArea, currentZone);
      let modified = panelPoints[0].length !== this.currentPanelPoints[0].length || panelPoints[1].length !== this.currentPanelPoints[1].length;
      modified = !modified ? panelPoints[0].some((point, index) => !point.equals(this.currentPanelPoints[0][index])) : modified;
      modified = !modified && panelPoints[1].length > 0 ? panelPoints[1].some((point, index) => !point.equals(this.currentPanelPoints[1][index])) : modified;

      return modified;
    }
    return false;
  }

  private buildPanelOptions(currentArea: AreaModel, currentZone: ZoneModel, firstLoad: boolean): IDeckGLObjectOptions | IDeckConcreteGLObjectOptions {
    this.currentPanelPoints = this.getPanelPoints(currentArea, currentZone);

    if (currentArea.deckType.id === DeckType.ConcreteFilledDeck && currentArea.deckFill.id !== DeckFill.NoFill) {
      return {
        events: [],
        GlObjectsIds: firstLoad ? [] : this.options.panel.GlObjectsIds,
        options: {
          length: interpolateJoistSpacing(this.getJoistSpacing(currentArea, currentZone)) * 2,
          scale: 7,
          fillThickness: interpolateFillThickness(currentArea.fillThickness.value),
          transparency: this.decking3dSettingsService.settings3d.transparentConcreteFill ? 0.5 : 1,
          position: 2,
          deckPoints: this.currentPanelPoints[0].map(point => new Vector2(point.x, point.y)),
          fastenerPoints: this.currentPanelPoints[1].map(point => new Vector2(point.x, point.y))
        }
      } as IDeckConcreteGLObjectOptions;
    } else {
      return {
        GlObjectsIds: firstLoad ? [] : this.options.panel.GlObjectsIds,
        options: {
          length: interpolateJoistSpacing(this.getJoistSpacing(currentArea, currentZone)) * 2,
          scale: 7,
          deckPoints: this.currentPanelPoints[0].map(point => new Vector2(point.x, point.y)),
          fastenerPoints: this.currentPanelPoints[1].map(point => new Vector2(point.x, point.y))
        },
        events: null
      } as IDeckGLObjectOptions;
    }
  }

  private buildWallBracketOptions(currentArea: AreaModel, currentZone: ZoneModel, firstLoad: boolean): IWallBracketGLObjectOptions {
    return {
      GlObjectsIds: firstLoad ? [] : this.options.wallBracket.GlObjectsIds,
      options:
      {
        length: interpolateJoistSpacing(this.getJoistSpacing(currentArea, currentZone)) * 2,
        width: 750
      },
      events: null
    };
  }

  private buildSupportOptions(firstLoad: boolean, currentArea: AreaModel): IBeamGLObjectOptions | IJoistGLObjectOptions {
    if (currentArea.supportConstruction.id == SupportConstruction.Beams) {
      return {
        GlObjectsIds: firstLoad ? [] : this.options.support.GlObjectsIds,
        kind: 'beam',
        options: {
          width: 50,
          width2: 0,
          flangeThickness: 8.5,
          height: 50,
          thickness: 5.5,
          length: 380,
          spacing: this.supportSpacing
        },
        events: null
      };
    }
    else {
      return {
        events: [],
        kind: 'joist',
        GlObjectsIds: firstLoad ? [] : this.options.support.GlObjectsIds,
        options: {
          length: 750,
          height: 75,
          extensionLength: 25,
          angleBase: 10,
          angleThickness: 1.5,
          angleSpace: 1.5,
          spacing: this.supportSpacing
        }
      };
    }

  }

  private buildLineOptions(currentArea: AreaModel, currentZone: ZoneModel, currentSettings: DesignSettings): ILineGLObjectOptions[] {
    const current3dSettings = this.decking3dSettingsService.settings3d;

    const lengthSettingUnit = this.deckingUnitHelperService.getBeamSpacingUnit(this.settings.length.id);
    const panelSettingUnit = this.deckingUnitHelperService.getPanelWidthUnit(this.settings.length.id);
    const qSettingUnit = this.settings.forcePerLength.id;
    const wSettingUnit = this.settings.stress.id;
    const gSettingUnit = this.settings.shearStiffness.id;

    const beamSpacingValue = this.unitService.convertUnitValueToUnit({
      value: this.getJoistSpacing(currentArea, currentZone),
      unit: UnitType.mm
    }, lengthSettingUnit);
    const deckWidthValue = this.unitService.convertUnitValueToUnit({
      value: currentArea.panelWidth.value,
      unit: UnitType.mm
    }, panelSettingUnit);
    const qValue = this.unitService.convertUnitValueToUnit({
      value: currentZone.q.value,
      unit: UnitType.N_mm
    }, qSettingUnit);
    const wValue = this.unitService.convertUnitValueToUnit({
      value: currentZone.w.value,
      unit: UnitType.Nmm2
    }, wSettingUnit);
    const gValue = this.unitService.convertUnitValueToUnit({
      value: currentZone.g.value,
      unit: UnitType.N_mm
    }, gSettingUnit);

    const beamSpacingFormatted = this.unitService.formatUnitValueArgs(beamSpacingValue.value, beamSpacingValue.unit, 2, null, null, null, true, null);
    const deckWidthFormatted = this.unitService.formatUnitValueArgs(deckWidthValue.value, deckWidthValue.unit, 0, null, null, null, true, null);

    // Q
    const qFormatted = this.unitService.formatUnitValueArgs(qValue.value, qValue.unit, 0, null, null, null, true, null);
    const qLocalizationText = this.localization.getString('Agito.Hilti.Profis3.Decking.GlModel.Loads.DiaphragmShear');
    const qText = qLocalizationText.includes('?#') ? 'Shear' : qLocalizationText;
    // W
    const wFormatted = this.unitService.formatUnitValueArgs(wValue.value, wValue.unit, 0, null, null, null, true, null);
    const wLocalizationText = this.localization.getString('Agito.Hilti.Profis3.Decking.GlModel.Loads.Uplift');
    const wText = wLocalizationText.includes('?#') ? 'Uplift' : wLocalizationText;
    // G'
    const gFormatted = this.unitService.formatUnitValueArgs(gValue.value, gValue.unit, 2, null, null, null, true, null);
    const gLocalizationText = this.localization.getString('Agito.Hilti.Profis3.Decking.GlModel.Loads.ShearStiffness');
    const gText = gLocalizationText.includes('?#') ? 'Stiffness' : gLocalizationText;

    const isConcreteFill = currentArea.deckType.id === DeckType.ConcreteFilledDeck;
    const isDeckFilled = currentArea.deckFill.id === DeckFill.NoFill;

    let UPLIFT_W = current3dSettings.inputs && !isConcreteFill;
    UPLIFT_W = isDeckFilled ? true : UPLIFT_W;

    return [
      {
        id: DeckingGlConstants.LINE_OPTIONS.DECK_WIDTH,
        text: deckWidthFormatted,
        options: {},
        events: [],
        enabled: current3dSettings.deckProperties,
        editable: current3dSettings.inputs
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.BEAM_SPACING,
        text: beamSpacingFormatted,
        options: {},
        events: [],
        enabled: current3dSettings.deckProperties,
        editable: current3dSettings.inputs
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.SIDELAP,
        enabled: current3dSettings.sidelapDimensions,
        text: currentZone.sidelapConnector ? this.deckingCodeListService.GetSidelapConnectorShortName(currentZone.sidelapConnector.id) : '',
        options: {},
        events: [],
        editable: current3dSettings.inputs
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.FASTENER,
        enabled: current3dSettings.sidelapDimensions,
        text: currentZone.frameFastener ? currentZone.frameFastener.value : '',
        options: {},
        events: [],
        editable: current3dSettings.inputs
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.DECK_GAUGE,
        enabled: current3dSettings.sidelapDimensions,
        text: currentZone.deckGauge ? currentZone.deckGauge.value.toString() + ' ga' : '',
        options: {},
        events: [],
        editable: current3dSettings.inputs
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.SHEAR_Q,
        enabled: current3dSettings.loads,
        text: qText + ' (Q)\n' + (qFormatted ?? ''),
        options: {},
        multiline: true,
        events: [],
        editable: current3dSettings.inputs
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.UPLIFT_W,
        enabled: current3dSettings.loads && currentSettings.requiredUpliftSubmittal.value,
        text: wText + ' (W)\n' + (wFormatted ?? ''),
        options: {},
        multiline: true,
        events: [],
        editable: UPLIFT_W
      },
      {
        id: DeckingGlConstants.LINE_OPTIONS.STIFFNESS_G,
        enabled: current3dSettings.loads && currentSettings.requiredShearStiffness.value,
        text: gText + ' (G\')\n' + (gFormatted ?? ''),
        options: {},
        multiline: true,
        events: [],
        editable: current3dSettings.inputs
      }
    ];
  }

  private buildSidelapOptions(firstLoad: boolean, currentZone: ZoneModel, sidelapItem: SidelapConnectorListItem, spacingType: SpacingType): ISidelapGLObjectOptions {
    if (sidelapItem?.isHILTIProduct) {
      return {
        GlObjectsIds: firstLoad ? [] : this.options.sidelap.GlObjectsIds,
        options:
        {
          spikeHeight: 12,
          shapeHeight: 30,
          headHeight: 5,
          headDiameter: 30,
          tessellation: 6,
          scale: 300,
          spacingType: spacingType,
          spacing: currentZone.side ? currentZone.side.value : 0,
          type: SidelapType.Hilti
        },
        events: firstLoad ? [{
          type: GLEvent.click, eventAction: () => {
            this.productSelectorService.expand();
          }
        }] : null
      } as ISidelapHiltiOptions;
    }

    return {
      GlObjectsIds: firstLoad ? [] : this.options.sidelap.GlObjectsIds,
      options:
      {
        height: 50,
        diameter: 15,
        scale: 300,
        spacingType: spacingType,
        spacing: currentZone.side ? currentZone.side.value : 0,
        type: SidelapType.Generic
      },
      events: firstLoad ? [{
        type: GLEvent.click, eventAction: () => {
          this.productSelectorService.expand();
        }
      }] : null
    } as ISidelapGenericOptions;
  }

  private buildFastenerOptions(modifiedObjects: IGLObjectOptions[], currentArea?: AreaModel, currentZone?: ZoneModel): IGLObjectOptions[] {
    if (currentZone && currentArea) {
      const fastenerItem = this.getFastenerItem(currentArea, currentZone);

      modifiedObjects.push(this.buildFastenerBorderOptions(false, fastenerItem?.value));
      modifiedObjects.push(this.buildFastenerMiddleOptions(false, fastenerItem?.value));
    }
    return modifiedObjects;
  }

  private buildFastenerBorderOptions(firstLoad: boolean, fastenerItem: FrameFastenerListItem): IFastenerGLObjectOptions {
    if (fastenerItem?.isHILTIProduct) {
      return {
        GlObjectsIds: firstLoad ? [] : this.options.fastenersBorder.GlObjectsIds,
        options: {
          height: 15,
          arc: 0.5,
          yRotation: -1.5,
          type: FastenerType.Hilti
        },
        events: []
      } as IFastenerHiltiOptions;
    }

    return {
      GlObjectsIds: firstLoad ? [] : this.options.fastenersBorder.GlObjectsIds,
      options: {
        height: 50,
        diameter: 15,
        scale: 300,
        yRotation: -1.5,
        type: FastenerType.Generic
      },
      events: []
    } as IFastenerGenericOptions;
  }

  private buildFastenerMiddleOptions(firstLoad: boolean, fastenerItem: FrameFastenerListItem): IFastenerGLObjectOptions {
    if (fastenerItem?.isHILTIProduct) {
      return {
        GlObjectsIds: firstLoad ? [] : this.options.fastenersMiddle.GlObjectsIds,
        options: {
          height: 15,
          arc: 1,
          yRotation: 0,
          type: FastenerType.Hilti
        },
        events: []
      } as IFastenerHiltiOptions;
    }

    return {
      GlObjectsIds: firstLoad ? [] : this.options.fastenersMiddle.GlObjectsIds,
      options: {
        height: 50,
        diameter: 15,
        scale: 300,
        yRotation: 0,
        type: FastenerType.Generic
      },
      events: []
    } as IFastenerGenericOptions;
  }

  private getSidelapItem(currentArea: AreaModel, currentZone: ZoneModel) {
    return this.deckingCodeListService.GetSidelapConnectorsDropdownItems(currentArea.deckPanel.id, currentArea.panelType.id)?.find(item => item.value?.id == currentZone.sidelapConnector?.id);
  }

  private getFastenerItem(currentArea: AreaModel, currentZone: ZoneModel) {
    return this.deckingCodeListService.GetFrameFastenersDropdownItems(currentArea.panelType.id)?.find(item => item.value?.id == currentZone.frameFastener?.id);
  }

  private getJoistSpacing(currentArea: AreaModel, currentZone: ZoneModel) {
    if (currentArea.zoneSpacing.value) {
      return currentZone.s.value;
    }
    return currentArea.beam.spacing.value;
  }

  private getPanelPoints(currentArea: AreaModel, currentZone: ZoneModel): [Vector2[], Vector2[]] {
    const panelCrossSection = this.deckingCodeListService.GetPanelCrossSection(currentArea.deckPanel.id);
    const panelCrossSectionPoints = !panelCrossSection || panelCrossSection.panelCrossSections.length == 0 ? [] : this.resizePanel(panelCrossSection.panelCrossSections.map(point => new Vector2(point.x, point.y)), currentArea.deckPanel.id, currentArea.panelWidth.value);
    const pattern = !currentZone.pattern || !panelCrossSection || !panelCrossSection.patternItems || panelCrossSection.patternItems.length == 0 ? null : panelCrossSection.patternItems.find(p => p.id == currentZone.pattern.id);
    const fastenerPoints = !pattern || !pattern.patternLayout || pattern.patternLayout.length == 0 ? [] : pattern.patternLayout.map(point => new Vector2(point.fastenerPosition, 0));

    return [panelCrossSectionPoints, fastenerPoints];
  }

  private resizePanel(panelCrossSection: Vector2[], panelId: number, panelWidth: number): Vector2[] {
    const panelWidths = this.deckingCodeListService.GetPanelWidths(panelId);
    if (panelWidths.length == 1 || panelWidth == panelWidths[panelWidths.length - 1][1]) {
      return panelCrossSection;
    }

    const inchWidth = panelWidths.filter(width => width[1] == panelWidth)[0][0];
    if (this.cutFromBeginning(panelWidths, inchWidth)) {
      const minXCoordinate = Math.min(...panelCrossSection.map(function (point) {
        return point.x;
      }));
      return panelCrossSection.filter(point => point.x <= (inchWidth - Math.abs(minXCoordinate)));
    }

    const newPanelCrossSection: Vector2[] = [];
    const maxPoint = inchWidth / 2;
    panelCrossSection.forEach(point => {
      if (point.x <= maxPoint && point.x >= maxPoint * -1) {
        newPanelCrossSection.push(point);
      }
    });
    return newPanelCrossSection;
  }

  private cutFromBeginning(panelWidths: number[][], panelWidth: number) {
    const has36Width = panelWidths.some(width => width[0] == 36);
    return has36Width && panelWidth == 30;
  }

  private isLineOption(glObject: IGLObjectOptions): glObject is ILineGLObjectOptions {
    return (glObject as ILineGLObjectOptions).text !== undefined;
  }
}
