import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { environment } from '../../../environments/environmentDecking';
import { TrackingData, SubstitutionUsageCounter, DeckingSubstitutionTrackingData } from '../../entities/decking-design/decking-tracking-model';
import { DeckingSubstitution } from '../../entities/decking-substitution/decking-substitution';
import { SubstitutionAreaModel } from '../../entities/decking-substitution/substitution-area';
import { DeckingDesignModeType } from '../../entities/enums/decking-design-mode-type';
import { TrackOnProjectOpenRequest } from '../../entities/tracking/track-on-design-open-request';
import { DeckingUserSettingsService } from '../decking-user-settings/user-settings.service';
import { ApiService } from '../external/api.service';
import { CommonCodeListService } from '../external/common-code-list.service';
import { CommonUserSettingsService } from '../external/common-user-settings.service';
import { FeatureVisibilityService } from '../external/feature-visibility.service';
import { LicenseService } from '../external/license.service';
import { LocalizationService } from '../external/localization.service';
import { UserService } from '../external/user.service';
import { BaseDeckingTrackingService } from './base-decking-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingSubstitutionTrackingService extends BaseDeckingTrackingService {

  override internalTrackingData: Partial<TrackingData> = {
    fastenerEstimationUsed: false,
    viewSidelapFastenerUser: false,
    viewFrameFastenerUsed: false
  };

  declare usageCounter: SubstitutionUsageCounter;

  constructor(
    private featureVisibilityService: FeatureVisibilityService,
    private apiService: ApiService,
    private localizationService: LocalizationService,
    private deckingUserSettingsService: DeckingUserSettingsService,
    private codeListCommon: CommonCodeListService,
    protected override userSettingsService: CommonUserSettingsService,
    protected override userService: UserService,
    protected override licenseService: LicenseService
  ) {
    super(userSettingsService, userService, licenseService);
    this.resetUsageCounter();
  }

  get isSubstitutionFeatureEnable(): boolean {
    return this.featureVisibilityService.isFeatureEnabled('Decking_SubstitutionBuilder');
  }

  public async trackSubstitutionOpened(substitution: DeckingSubstitution): Promise<void> {
    this.resetUsageCounter(substitution);
    if (this.isSubstitutionFeatureEnable) {
      const url = `${environment.deckingTrackingServiceUrl}api/SubstitutionTracking/TrackOnProjectSubstitutionOpen`;
      const data = this.getSubstitutionTrackingData(substitution);

      const request = new HttpRequest('POST', url, data, {
        responseType: 'json'
      });

      await this.apiService.request(request, {
        supressErrorMessage: true
      });
    }
  }

  public async trackSubstitutionClosed(substitution: DeckingSubstitution): Promise<void> {
    if (this.isSubstitutionFeatureEnable) {
      const url = `${environment.deckingTrackingServiceUrl}api/SubstitutionTracking/TrackOnProjectSubstitutionClose`;
      const data = this.substitutionTrackingDataFactory(substitution);

      const request = new HttpRequest('POST', url, data, {
        responseType: 'json'
      });

      await this.apiService.request(request, {
        supressErrorMessage: true
      });
    }
  }

  public async trackSubstitutionActivity(substitution: DeckingSubstitution): Promise<void> {
    if (this.isSubstitutionFeatureEnable) {
      const url = `${environment.deckingTrackingServiceUrl}api/SubstitutionTracking/TrackSubstitutionActivity`;
      const data = this.substitutionTrackingDataFactory(substitution);

      const request = new HttpRequest('POST', url, data, {
        responseType: 'json'
      });
      await this.apiService.request(request, {
        supressErrorMessage: true
      });
    }
  }

  private getSubstitutionTrackingData(substitution: DeckingSubstitution): TrackOnProjectOpenRequest {
    // Set date
    const date = getJSONDateWithTimezoneOffset();
    // Set user details
    const userDetails = this.getUserDetails(date.timezoneOffset);

    return {
      designId: substitution.id,
      designType: this.diaphragmDesignType,
      language: this.localizationService.selectedLanguage,
      region: this.deckingUserSettingsService.deckingSettings.region.value ?? '',
      timestamp: date.timestamp as any,
      userDetails: userDetails,
      uiVersion: environment.moduleVersion,
      isInternal: this.userService.isInternalHiltiUser(),
    };
  }

  private substitutionTrackingDataFactory(substitution: DeckingSubstitution): DeckingSubstitutionTrackingData {
    const date = getJSONDateWithTimezoneOffset();
    // Set user details
    const userDetails = this.getUserDetails(date.timezoneOffset);

    return {
      projectType: substitution.projectType ?? DeckingDesignModeType.SubstitutionBuilderMode,
      timestamp: date.timestamp,
      uiVersion: environment.moduleVersion,
      isInternal: this.userService.isInternalHiltiUser(),
      designId: substitution.id,
      projectOpenType: 0,
      language: this.localizationService.selectedLanguage,
      region: this.deckingUserSettingsService.deckingSettings.region?.value ?? '',
      userDetails: userDetails,
      projectSubstitution: substitution,
      designMethod: substitution.settings.designMethod.value,
      designStandard: substitution.settings.designStandard.value,
      defaultLoad: substitution.settings.relevantLoads.value,
      windVsSeismic: substitution.settings.substitutionWindAndSeismicLoadsAtZoneLevel?.value,
      stiffnessUsed: substitution.settings.substitutionRequiredShearStiffness?.value,
      upliftUsed: substitution.settings.substitutionRequiredUpliftSubmittal?.value,
      usageCounter: this.usageCounter,
      fastenerEstimationUsed: this.internalTrackingData.fastenerEstimationUsed,
      viewFrameFastenerUsed: this.internalTrackingData.viewFrameFastenerUsed,
      viewSidelapFastenerUsed: this.internalTrackingData.viewSidelapFastenerUser,
      definitionOfSidelapConnectors: substitution.settings.definitionOfSidelapConnectors,
      sidelapsNumberSettings: substitution.settings.sidelapsNumberSettings,
      sidelapsSpacingSettings: substitution.settings.sidelapsSpacingSettings
    };
  }

  public resetUsageCounter(substitution: DeckingSubstitution = null) {
    if (substitution) {
      this.usageCounter = {
        totalAreas: substitution.areas.length,
        totalZones: this.countZones(substitution.areas),
        reportCreated: 0,
        reportPreviewed: 0,
        reportLanguage: '',
        reportUnitType: '',
        designExported: 0,
        totalReportCustomTemplate: 0,
        totalReportDefaultTemplate: 0,
        specificationTextUsed: 0
      };
    } else {
      this.usageCounter = {
        totalAreas: 0,
        totalZones: 0,
        reportCreated: 0,
        reportPreviewed: 0,
        reportLanguage: '',
        reportUnitType: '',
        designExported: 0,
        totalReportCustomTemplate: 0,
        totalReportDefaultTemplate: 0,
        specificationTextUsed: 0
      };
    }

    this.internalTrackingData = {
      fastenerEstimationUsed: false,
      viewSidelapFastenerUser: false,
      viewFrameFastenerUsed: false,
    };
  }

  protected override countZones(areas: Array<SubstitutionAreaModel>) {
    let count = 0;
    areas.forEach((area) => (count += area.zones.length));
    return count;
  }

  addReportCustomTemplate(reportsToAdd = 1): void {
    this.usageCounter.totalReportCustomTemplate += reportsToAdd;
  }

  addReportDefaultTemplate(reportsToAdd = 1): void {
    this.usageCounter.totalReportDefaultTemplate += reportsToAdd;
  }

  setViewFrameFastenerUsed(isUsed: boolean): void {
    this.internalTrackingData.viewFrameFastenerUsed = isUsed;
  }

  setViewSidelapFastenerUsed(isUsed: boolean): void {
    this.internalTrackingData.viewSidelapFastenerUser = isUsed;
  }

  setLastReportSelectedLanguage(languageId: number): void {
    const language = (this.codeListCommon.commonCodeLists[CommonCodeList.Language] as Language[]).find(x => x.id === languageId);
    this.usageCounter.reportLanguage = language?.nameResourceKey ? this.localizationService.getString(language.nameResourceKey) : languageId.toString();
  }

  setLastReportSelectedUnitType(unitType: string): void {
    this.usageCounter.reportUnitType = unitType;
  }

  addSpecificationTextUsed(toAdd = 1): void {
    this.usageCounter.specificationTextUsed += toAdd;
}
}
