import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ZoneAlternative } from '../../../entities/decking-design/zone-alternative-model';
import { environment } from '../../../../environments/environmentDecking';
import { ApiService } from  '../../external/api.service';
import { DeckingSubstitutionService } from '../../decking-design/decking-substitution.service';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';

@Injectable({
    providedIn: 'root'
})
export class DeckingSubstitutionZonesAlternativesService {

    constructor(
        private apiService: ApiService,
        private deckingSubstitutionService: DeckingSubstitutionService) {
    }

    public async getAlternatives(currentDesign: DeckingSubstitution): Promise<ZoneAlternative[]> {
        const url = `${environment.deckingCalculationServiceUrl}api/ZoneSubstitutionAlternatives/` + currentDesign.currentAreaIndex + `/` + currentDesign.currentZoneIndex;
        const responsePromise = await this.apiService.request<ZoneAlternative[]>(new HttpRequest('POST', url, currentDesign));
        return responsePromise.body;
    }

    public async setAlternatives(deckingSubstitution: DeckingSubstitution): Promise<void>{
        deckingSubstitution.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.zoneSpecified.result.numberOfEdgeSupportConnections) {
                    zone.zoneSpecified.result.numberOfEdgeSupportConnections = {value: 0};
                }
                if(!zone.zoneSubstituted.result.numberOfEdgeSupportConnections) {
                    zone.zoneSubstituted.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        const url = `${environment.deckingDesignServiceUrl}api/SubstitutionCommand/save`;
        try {
            const response = await this.apiService.request<DeckingSubstitution>(new HttpRequest('POST', url, deckingSubstitution));
            const savedDesign = response.body;
            savedDesign.saved = true;
            this.deckingSubstitutionService.setSubstitution(savedDesign);
          } catch (e) {
            console.error(e);
          }
    }
}
