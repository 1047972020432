import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { RadioButtonProps, RadioLook } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';
import { DefinitionOfSidelapConnectorTypes, IDeckingUserSettings } from './../../../entities/settings/decking-user-settings';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingUnitsHelperService } from './../../../services/decking-units-helper/decking-units-helper.service';
import { UnitService } from './../../../services/external/unit.service';
import { DeckingUserSettingsService } from './../../../services/decking-user-settings/user-settings.service';
import { BaseDiaphragmDesignSettingsComponent } from './../../../components/common/diaphragm-design-settings/diaphragm-design-settings.component';
import { FeatureVisibilityService } from './../../../services/external/feature-visibility.service';
import { DeckingDesignModeType } from './../../../entities/enums/decking-design-mode-type';
import { Region } from './../../../entities/enums/decking-settings-region';

@Component({
  templateUrl: './decking-settings-diaphragm.component.html',
  styleUrls: ['./decking-settings-diaphragm.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DeckingSettingsDiaphragmComponent extends BaseDiaphragmDesignSettingsComponent implements OnInit, OnChanges, OnDestroy {
  readonly ONE_INCH_IN_MM: number = 25.4;
  readonly maxValueSidelapIncrement: number = 20 * this.ONE_INCH_IN_MM;
  readonly maxValueSidelapToInput: number = 100 * this.ONE_INCH_IN_MM;

  ConnectorsUnitFromLength: Unit = Unit.None;
  PercentUnit: Unit = Unit.percent;

  // submitting and loading states for disabling
  @Input() submitting: boolean;
  // data binding with the current user settings
  @Input() public deckingSetting: IDeckingUserSettings;
  // data binding with selected region
  @Input() public selectedRegion: CommonRegion;
  // subject for triggering reload data method
  @Input() reloadDataSubject$: Subject<void>;
  // subject for triggering save decking user settings
  @Input() saveDataSubject$: Subject<void>;

  @Output() public deckingSettingChanged: EventEmitter<IDeckingUserSettings> = new EventEmitter<IDeckingUserSettings>();

  loading: boolean;

  zoneInputCheckboxesCheckedItems: Set<number>;
  substitutionZoneInputCheckboxesCheckedItems: Set<number>;
  isSubstitutionEnabled = false;
  isMultiCodeEnabled = false;
  unsubscribe$ = new Subject<void>();

  defaultApplicationControl: RadioButtonProps<DeckingDesignModeType>;
  showWasteFactor = false;
  public deckingDesignModeType = DeckingDesignModeType;

  constructor(
    localization: LocalizationService,
    userSettingsService: DeckingUserSettingsService,
    private unitService: UnitService,
    private deckingUnitsHelperService: DeckingUnitsHelperService,
    private featureVisibilityService: FeatureVisibilityService
  ) {
    super(localization, userSettingsService);
  }

  ngOnInit(): void {
    this.initRadioButtonItems();
    this.isSubstitutionEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_SubstitutionBuilder');
    this.isMultiCodeEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_MultiCodes');
    this.showWasteFactor = this.isSubstitutionEnabled; 
  }

  private initRadioButtonItems(): void {
    this.defaultApplicationControl = {
      title: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.DefaultApplicationChoice'),
      look: RadioLook.Normal,
      id: 'diaphragm-default-application',
      items: [
        {
          text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignMode'),
          value: DeckingDesignModeType.DesignMode,
          disabled: false,
          id: 'diaphragm-default-application-design'
        },
        {
          text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.SubstitutionBuilderMode'),
          value: DeckingDesignModeType.SubstitutionBuilderMode,
          disabled: false,
          id: 'diaphragm-default-application-substitution-builder'
        },
        {
          text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.AlwaysAsk'),
          value: DeckingDesignModeType.AlwaysAskMode,
          disabled: false,
          id: 'diaphragm-default-application-always-ask'
        }
      ],
      selectedValue: DeckingDesignModeType.AlwaysAskMode
    };
  }

  public get SidelapConnectorTypes() {
    return DefinitionOfSidelapConnectorTypes;
  }

  public get regionDescription(): string {
    let translation = this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.Status.Ok');
    translation = format(translation, this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
    return translation;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deckingSetting']?.isFirstChange()) {
      this.updateIncrementUnitFromLength(this.ConnectorsUnitFromLength);
      this.loadInitialUserSettings();
    }
    if (changes['reloadDataSubject$']?.isFirstChange()) {
      this.reloadDataSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.reloadDeckingSettings();
      });
    }
    if (changes['saveDataSubject$']?.isFirstChange()) {
      this.saveDataSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.saveSettings();
      });
    }
    if (changes['selectedRegion']) {
      this.handleRegionChangedDecking(this.selectedRegion);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onSidelapConnectorToInputChanged(): void {
    if (this.deckingSetting.sidelapsSpacing.sidelapSpacingIncrement.value > this.deckingSetting.sidelapsSpacing.sidelapSpacingTo.value) {
      this.deckingSetting.sidelapsSpacing.sidelapSpacingIncrement.value = this.deckingSetting.sidelapsSpacing.sidelapSpacingTo.value;
    }
  }

  public onChangeRegion(regionId: number): void {
    //  switch data based in the selected region
    this.deckingSetting = { ...this.userSettingsService.deckingSettingsCollection.regionDefault.filter(e => e.region.index == regionId)[0] };
    this.deckingSetting.requiredShearStiffness.value = true;
    this.deckingSetting.requiredUpliftSubmittal.value = true;
    this.deckingSetting.windAndSeismicLoadsAtZoneLevel.value = true;
    this.deckingSetting.substitutionRequiredShearStiffness.value = false;
    this.deckingSetting.substitutionRequiredUpliftSubmittal.value = false;
    this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel.value = true;
    this.isImperial = regionId === 1;
    this.zoneInputCheckboxesCheckedItems.add(1);
    this.zoneInputCheckboxesCheckedItems.add(2);
    this.zoneInputCheckboxesCheckedItems.add(3);
    this.zoneInputCheckboxesCheckedItems.add(4);
    this.substitutionZoneInputCheckboxesCheckedItems = new Set();
    this.substitutionZoneInputCheckboxesCheckedItems.add(3);

    this.onLengthInputChanged();
    this.updateIncrementUnitFromLength(this.ConnectorsUnitFromLength);
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  onLengthInputChanged() {
    this.ConnectorsUnitFromLength = this.deckingSetting.length.unitValue;
  }

  // subscribed to this event to update the Increment combo values. Dropdown does not allow a unit configuration.
  public onLengthChanged(lengthId: number): void {
    const length = this.userSettingsService.deckingSettingsCollection.length.filter(e => e.index == lengthId)[0];
    this.deckingSetting.length = length;
    this.onLengthInputChanged();
    this.updateIncrementUnitFromLength(this.ConnectorsUnitFromLength);
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onAreaChanged(areaId: number): void {
    const area = this.userSettingsService.deckingSettingsCollection.area.filter(e => e.index == areaId)[0];
    this.deckingSetting.area = area;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onStressChanged(stressId: number): void {
    const area = this.userSettingsService.deckingSettingsCollection.stress.filter(e => e.index == stressId)[0];
    this.deckingSetting.stress = area;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onForceChanged(forceId: number): void {
    const force = this.userSettingsService.deckingSettingsCollection.force.filter(e => e.index == forceId)[0];
    this.deckingSetting.force = force;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onMomentChanged(momentId: number): void {
    const moment = this.userSettingsService.deckingSettingsCollection.moment.filter(e => e.index == momentId)[0];
    this.deckingSetting.moment = moment;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onForcePerLengthChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.forcePerLength.filter(e => e.index == id)[0];
    this.deckingSetting.forcePerLength = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onShearStiffnessChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.shearStiffness.filter(e => e.index == id)[0];
    this.deckingSetting.shearStiffness = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onMomentPerLengthChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.momentPerLength.filter(e => e.index == id)[0];
    this.deckingSetting.momentPerLength = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onDesignMethodChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.designMethod.filter(e => e.index == id)[0];
    this.deckingSetting.designMethod = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onDesignStandardChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.designStandard.filter(e => e.index == id)[0];
    this.deckingSetting.designStandard = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onDefaultLoadsChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.relevantLoads.filter(e => e.index == id)[0];
    this.deckingSetting.relevantLoads = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onDefinitionOfSidelapConnectorChanged(id: number): void {
    const value = this.userSettingsService.deckingSettingsCollection.definitionOfSidelapConnectors.filter(e => e.index == id)[0];
    this.deckingSetting.definitionOfSidelapConnectors = value;
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onZoneInputCheckboxesChanged(val: Set<number>): void {
    this.deckingSetting.requiredShearStiffness.value = this.deckingSetting.requiredUpliftSubmittal.value = this.deckingSetting.calculateIntegration.value = this.deckingSetting.windAndSeismicLoadsAtZoneLevel.value = false;
    val.forEach(element => {
      switch (element) {
        case 1:
          this.deckingSetting.requiredShearStiffness.value = true;
          break;
        case 2:
          this.deckingSetting.calculateIntegration.value = true;
          break;
        case 3:
          this.deckingSetting.windAndSeismicLoadsAtZoneLevel.value = true;
          break;
        case 4:
          this.deckingSetting.requiredUpliftSubmittal.value = true;
          break;
      }
    });
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  public onSubstitutionZoneInputCheckboxesChanged(val: Set<number>): void {
    this.deckingSetting.substitutionRequiredShearStiffness.value = this.deckingSetting.substitutionRequiredUpliftSubmittal.value = this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel.value = false;
    val.forEach(element => {
      switch (element) {
        case 1:
          this.deckingSetting.substitutionRequiredShearStiffness.value = true;
          break;
        case 2:
          this.deckingSetting.calculateIntegration.value = true;
          break;
        case 3:
          this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel.value = true;
          break;
        case 4:
          this.deckingSetting.substitutionRequiredUpliftSubmittal.value = true;
          break;
      }
    });
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  /**
   * Save User settings in DeckingUserService service
   */
  private saveSettings() {
    this.updateDeckingUserSettings();
    this.userSettingsService.save()
      .then(() => {
        this.reloadDeckingSettings();
      })
      .catch((err) => {
        if (err instanceof Error) {
          console.error(err);
        }
      });
  }

  /**
   * @description - this function is used to store the initial user settings based
   * in the saved user settings or the default setting
   */
  private loadInitialUserSettings(): void {
    this.copyDeckingSettingsValues({ ...this.userSettingsService.deckingSettings });
    this.isImperial = this.deckingSetting?.isImperial?.value ?? undefined;
    this.onLengthInputChanged();
    this.setSubstitutionBuilderDefaults();
    this.zoneInputCheckboxesCheckedItems = new Set();
    this.substitutionZoneInputCheckboxesCheckedItems = new Set();
    if (this.deckingSetting.requiredShearStiffness.value) this.zoneInputCheckboxesCheckedItems.add(1);
    if (this.deckingSetting.calculateIntegration.value) this.zoneInputCheckboxesCheckedItems.add(2);
    if (this.deckingSetting.windAndSeismicLoadsAtZoneLevel.value) this.zoneInputCheckboxesCheckedItems.add(3);
    if (this.deckingSetting.requiredUpliftSubmittal.value) this.zoneInputCheckboxesCheckedItems.add(4);

    if (this.deckingSetting.substitutionRequiredShearStiffness.value) this.substitutionZoneInputCheckboxesCheckedItems.add(1);
    if (this.deckingSetting.substitutionRequiredUpliftSubmittal.value) this.substitutionZoneInputCheckboxesCheckedItems.add(4);
    if (this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel.value) this.substitutionZoneInputCheckboxesCheckedItems.add(3);
  }

  private setSubstitutionBuilderDefaults() {
    if (!this.deckingSetting.designMode && this.showWasteFactor) {
      this.deckingSetting.designMode = { value: DeckingDesignModeType.AlwaysAskMode };
      this.deckingSetting.wasteFactor = { value: 5 };
    }
  }

  /**
     * Fill our data with the initial user settings
     */
  public reloadDeckingSettings() {
    this.copyDeckingSettingsValues({ ...this.userSettingsService.deckingSettings });
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  /**
   * Handle region change from pe-ui app settings component
   * @param region Region selected in app settings component 
   */
  private handleRegionChangedDecking(region: CommonRegion) {
    if (region) {
      const currentEtag = this.userSettingsService.deckingSettings.eTag;

      if (region.countryCode == 'ca') {
        this.deckingSetting = this.userSettingsService.deckingSettingsCollection.regionDefault.filter(e => e.region.countryCode == region.countryCode)[0];
        this.deckingSetting.isImperial = this.deckingSetting?.isImperial ?? { value: false };
      } else {
        // If the region is not canada, then we need get the default settings for the US.
        this.deckingSetting = this.userSettingsService.deckingSettingsCollection.regionDefault.filter(e => e.region.value == 'US')[0];
        this.deckingSetting.isImperial = this.deckingSetting?.isImperial ?? { value: true };
      }
      this.isImperial = this.deckingSetting.isImperial.value;
      this.deckingSetting.eTag = currentEtag;
      this.deckingSettingChanged.emit(this.deckingSetting);
    }
  }

  /**
     * Copy the settings values from user settings service as value not reference to
     * deckingData local object.
     *
     * @param from User Settings Service as Value
     */
  private copyDeckingSettingsValues(from: IDeckingUserSettings) {
    if (from != null) {
      this.deckingSetting.region = from.region;
      this.deckingSetting.isImperial = from.isImperial;
      this.deckingSetting.area = from.area;
      this.deckingSetting.length = from.length;
      this.deckingSetting.stress = from.stress;
      this.deckingSetting.moment = from.moment;
      this.deckingSetting.force = from.force;
      this.deckingSetting.forcePerLength = from.forcePerLength;
      this.deckingSetting.shearStiffness = from.shearStiffness;
      this.deckingSetting.momentPerLength = from.momentPerLength;
      this.deckingSetting.designMethod = from.designMethod;
      this.deckingSetting.designStandard = from.designStandard;
      this.deckingSetting.relevantLoads = from.relevantLoads;
      this.deckingSetting.sidelapsNumber = from.sidelapsNumber ? {
        numberOfSidelapsFrom: { value: from.sidelapsNumber.numberOfSidelapsFrom.value },
        numberOfSidelapsTo: { value: from.sidelapsNumber.numberOfSidelapsTo.value },
        numberOfSidelapsIncrement: { value: from.sidelapsNumber.numberOfSidelapsIncrement.value },
      } : null;
      this.deckingSetting.sidelapsSpacing = from.sidelapsSpacing ? {
        sidelapSpacingFrom: { value: from.sidelapsSpacing.sidelapSpacingFrom.value },
        sidelapSpacingTo: { value: from.sidelapsSpacing.sidelapSpacingTo.value },
        sidelapSpacingIncrement: { value: from.sidelapsSpacing.sidelapSpacingIncrement.value },
      } : null;
      this.copyDeckingZoneInputSettingsValues(from);
      this.deckingSetting.calculateIntegration = from.calculateIntegration ? { value: from.calculateIntegration.value } : null;
      this.deckingSetting.definitionOfSidelapConnectors = from.definitionOfSidelapConnectors;
      this.deckingSetting.wasteFactor = cloneDeep(from.wasteFactor);
      this.deckingSetting.designMode = cloneDeep(from.designMode);
      this.deckingSetting.eTag = from.eTag;
    }
  }

  private copyDeckingZoneInputSettingsValues(from: IDeckingUserSettings) {
    this.deckingSetting.requiredShearStiffness = from.requiredShearStiffness ? { value: from.requiredShearStiffness.value } : null;
    this.deckingSetting.requiredUpliftSubmittal = from.requiredUpliftSubmittal ? { value: from.requiredUpliftSubmittal.value } : null;
    this.deckingSetting.windAndSeismicLoadsAtZoneLevel = from.windAndSeismicLoadsAtZoneLevel ? { value: from.windAndSeismicLoadsAtZoneLevel.value } : null;
    this.deckingSetting.substitutionRequiredShearStiffness = from.substitutionRequiredShearStiffness ? { value: from.substitutionRequiredShearStiffness.value } : null;
    this.deckingSetting.substitutionRequiredUpliftSubmittal = from.substitutionRequiredUpliftSubmittal ? { value: from.substitutionRequiredUpliftSubmittal.value } : null;
    this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel = from.substitutionWindAndSeismicLoadsAtZoneLevel ? { value: from.substitutionWindAndSeismicLoadsAtZoneLevel.value } : null;
  }

  /**
   * Update the decking user settings model with the data from UI controls.
   */
  public updateDeckingUserSettings() {

    const isRegionDifferent = this.userSettingsService.deckingSettings.region.countryCode !== this.deckingSetting.region.countryCode;
    const isLengthDifferent = this.userSettingsService.deckingSettings.length !== this.deckingSetting.length;
    const isAreaDifferent = this.userSettingsService.deckingSettings.area !== this.deckingSetting.area;
    const isStressDifferent = this.userSettingsService.deckingSettings.stress !== this.deckingSetting.stress;
    const isMomentDifferent = this.userSettingsService.deckingSettings.moment !== this.deckingSetting.moment;
    const isForceDifferent = this.userSettingsService.deckingSettings.force !== this.deckingSetting.force;
    const isForcePerLengthDifferent =
      this.userSettingsService.deckingSettings.forcePerLength !== this.deckingSetting.forcePerLength;
    const isMomentPerLengthDifferent =
      this.userSettingsService.deckingSettings.momentPerLength !== this.deckingSetting.momentPerLength;
    const isDesignMethodDifferent = this.userSettingsService.deckingSettings.designMethod !== this.deckingSetting.designMethod;
    const isDesignStandardDifferent = this.userSettingsService.deckingSettings.designStandard !== this.deckingSetting.designStandard;
    const isRelevantLoadsDifferent = this.userSettingsService.deckingSettings.relevantLoads !== this.deckingSetting.relevantLoads;
    const isDefinitionOfSidelapConnectorsDifferent =
      this.userSettingsService.deckingSettings.definitionOfSidelapConnectors !== this.deckingSetting.definitionOfSidelapConnectors;
    const isRequiredShearStiffnessDifferent =
      this.userSettingsService.deckingSettings.requiredShearStiffness.value !== this.deckingSetting.requiredShearStiffness.value;
    const isRequiredUpliftSubmittalDifferent =
      this.userSettingsService.deckingSettings.requiredUpliftSubmittal.value !== this.deckingSetting.requiredUpliftSubmittal.value;
    const isSubstitutionRequiredShearStiffnessDifferent =
      this.userSettingsService.deckingSettings.substitutionRequiredShearStiffness.value !== this.deckingSetting.substitutionRequiredShearStiffness.value;
    const isSubstitutionRequiredUpliftSubmittalDifferent =
      this.userSettingsService.deckingSettings.substitutionRequiredUpliftSubmittal.value !== this.deckingSetting.substitutionRequiredUpliftSubmittal.value;
    const isSubstitutionWindAndSeismicLoadsAtZoneLevelDifferent =
      this.userSettingsService.deckingSettings.substitutionWindAndSeismicLoadsAtZoneLevel.value !== this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel.value;
    const isCalculateIntegrationDifferent =
      this.userSettingsService.deckingSettings.calculateIntegration.value !== this.deckingSetting.calculateIntegration.value;
    const isWindAndSeismicLoadsAtZoneLevelDifferent =
      this.userSettingsService.deckingSettings.windAndSeismicLoadsAtZoneLevel.value !== this.deckingSetting.windAndSeismicLoadsAtZoneLevel.value;
    const isShearStiffnessDifferent = this.userSettingsService.deckingSettings.shearStiffness !== this.deckingSetting.shearStiffness;
    const isSidelapSpacingFromDifferent =
      this.userSettingsService.deckingSettings.sidelapsSpacing.sidelapSpacingFrom.value !== this.deckingSetting.sidelapsSpacing.sidelapSpacingFrom.value;
    const isSidelapSpacingIncrementDifferent =
      this.userSettingsService.deckingSettings.sidelapsSpacing.sidelapSpacingIncrement.value !== this.deckingSetting.sidelapsSpacing.sidelapSpacingIncrement.value;
    const isSidelapSpacingToDifferent =
      this.userSettingsService.deckingSettings.sidelapsSpacing.sidelapSpacingTo.value !== this.deckingSetting.sidelapsSpacing.sidelapSpacingTo.value;    
    const isNumberSidelapSpacingFromDifferent =
      this.userSettingsService.deckingSettings.sidelapsNumber.numberOfSidelapsFrom.value !== this.deckingSetting.sidelapsNumber.numberOfSidelapsFrom.value;
    const isNumberSidelapSpacingToDifferent =
      this.userSettingsService.deckingSettings.sidelapsNumber.numberOfSidelapsTo.value !== this.deckingSetting.sidelapsNumber.numberOfSidelapsTo.value;
    const isNumberSidelapSpacingIncrementDifferent =
      this.userSettingsService.deckingSettings.sidelapsNumber.numberOfSidelapsIncrement.value !== this.deckingSetting.sidelapsNumber.numberOfSidelapsIncrement.value;    
    const isDesignModeDifferent =
      this.showWasteFactor && (!this.userSettingsService.deckingSettings.designMode || this.userSettingsService.deckingSettings.designMode.value !== this.deckingSetting.designMode.value);
    const isWasteFactorDifferent =
      this.showWasteFactor && (!this.userSettingsService.deckingSettings.wasteFactor || this.userSettingsService.deckingSettings.wasteFactor.value !== this.deckingSetting.wasteFactor.value);
    
    this.userSettingsService.deckingSettings.isDirty =
      isRegionDifferent ||
      isLengthDifferent ||
      isAreaDifferent ||
      isStressDifferent ||
      isMomentDifferent ||
      isForceDifferent ||
      isForcePerLengthDifferent ||
      isMomentPerLengthDifferent ||
      isDesignMethodDifferent ||
      isDesignStandardDifferent ||
      isRelevantLoadsDifferent ||
      isDefinitionOfSidelapConnectorsDifferent ||
      isRequiredShearStiffnessDifferent ||
      isRequiredUpliftSubmittalDifferent ||
      isSubstitutionRequiredShearStiffnessDifferent ||
      isSubstitutionRequiredUpliftSubmittalDifferent ||
      isSubstitutionWindAndSeismicLoadsAtZoneLevelDifferent ||
      isCalculateIntegrationDifferent ||
      isWindAndSeismicLoadsAtZoneLevelDifferent ||
      isShearStiffnessDifferent ||
      isSidelapSpacingFromDifferent ||      
      isSidelapSpacingIncrementDifferent ||
      isSidelapSpacingToDifferent ||
      isNumberSidelapSpacingFromDifferent ||
      isNumberSidelapSpacingIncrementDifferent ||
      isNumberSidelapSpacingToDifferent ||
      isDesignModeDifferent ||
      isWasteFactorDifferent;

    if (this.userSettingsService.deckingSettings.isDirty) {
      this.userSettingsService.deckingSettings.region = this.deckingSetting.region;
      this.userSettingsService.deckingSettings.length = this.deckingSetting.length;
      this.userSettingsService.deckingSettings.area = this.deckingSetting.area;
      this.userSettingsService.deckingSettings.stress = this.deckingSetting.stress;
      this.userSettingsService.deckingSettings.moment = this.deckingSetting.moment;
      this.userSettingsService.deckingSettings.force = this.deckingSetting.force;
      this.userSettingsService.deckingSettings.forcePerLength = this.deckingSetting.forcePerLength;
      this.userSettingsService.deckingSettings.momentPerLength = this.deckingSetting.momentPerLength;
      this.userSettingsService.deckingSettings.designMethod = this.deckingSetting.designMethod;
      this.userSettingsService.deckingSettings.designStandard = this.deckingSetting.designStandard;
      this.userSettingsService.deckingSettings.relevantLoads = this.deckingSetting.relevantLoads;
      this.userSettingsService.deckingSettings.definitionOfSidelapConnectors = this.deckingSetting.definitionOfSidelapConnectors;
      this.userSettingsService.deckingSettings.sidelapsNumber = this.deckingSetting.sidelapsNumber;
      this.userSettingsService.deckingSettings.sidelapsSpacing = this.deckingSetting.sidelapsSpacing;
      this.userSettingsService.deckingSettings.requiredShearStiffness = this.deckingSetting.requiredShearStiffness;
      this.userSettingsService.deckingSettings.requiredUpliftSubmittal = this.deckingSetting.requiredUpliftSubmittal;
      this.userSettingsService.deckingSettings.substitutionRequiredShearStiffness = this.deckingSetting.substitutionRequiredShearStiffness;
      this.userSettingsService.deckingSettings.substitutionRequiredUpliftSubmittal = this.deckingSetting.substitutionRequiredUpliftSubmittal;
      this.userSettingsService.deckingSettings.substitutionWindAndSeismicLoadsAtZoneLevel = this.deckingSetting.substitutionWindAndSeismicLoadsAtZoneLevel;
      this.userSettingsService.deckingSettings.calculateIntegration = this.deckingSetting.calculateIntegration;
      this.userSettingsService.deckingSettings.windAndSeismicLoadsAtZoneLevel = this.deckingSetting.windAndSeismicLoadsAtZoneLevel;
      this.userSettingsService.deckingSettings.shearStiffness = this.deckingSetting.shearStiffness;
      this.userSettingsService.deckingSettings.isImperial = this.deckingSetting.isImperial || undefined;
      this.userSettingsService.deckingSettings.wasteFactor = this.deckingSetting.wasteFactor;
      this.userSettingsService.deckingSettings.designMode = this.deckingSetting.designMode;
    }
  }

  private updateIncrementUnitFromLength(unit: Unit): void {
    if (unit) {
      this.incrementItems = this.originalIncrementItems.map(x => ({
        value: x.level,
        text: this.unitService.formatUnitValue(this.unitService.convertUnitValueToUnit(
          {
            value: +x.level,
            unit: this.unitService.getInternalUnit(UnitGroup.Length)
          },
          unit)),
        level: x.level
      } as DropdownItem<number>));
    }
  }

  public defaultApplicationControlSelectedValueChange(selectedValue: DeckingDesignModeType): void {
    if (this.deckingSetting.designMode) {
      this.deckingSetting.designMode.value = selectedValue;
      this.deckingSettingChanged.emit(this.deckingSetting);
    }
  }

  public wasteFactorSelectedValueChange(selectedValue: number): void {
    if (this.deckingSetting.wasteFactor) {
      this.deckingSetting.wasteFactor.value = selectedValue;
      this.deckingSettingChanged.emit(this.deckingSetting);
    }
  }

  measureUnitsUpdated(isImperial: boolean): void {
    const regionId = isImperial ? Region.US: Region.Canada;
    const tempRegion = cloneDeep(this.deckingSetting.region);
    const tempDesignMethod = cloneDeep(this.deckingSetting.designMethod);
    const tempDesignStandard = cloneDeep(this.deckingSetting.designStandard);
    this.deckingSetting = cloneDeep(this.userSettingsService.deckingSettingsCollection.regionDefault.filter(e => e.region.index == regionId)[0]);
    this.deckingSetting.region = tempRegion;
    this.deckingSetting.isImperial = {
      value: this.isImperial
    };
    this.deckingSetting.designMethod = tempDesignMethod;
    this.deckingSetting.designStandard = tempDesignStandard;
    
    this.onLengthInputChanged();
    this.updateIncrementUnitFromLength(this.ConnectorsUnitFromLength);
    this.deckingSettingChanged.emit(this.deckingSetting);
  }

  resetUnits(): void {
    if (typeof (this.isImperial) !== 'undefined') {
      this.measureUnitsUpdated(this.isImperial);
    }
  }
}
